import React, { useState } from "react";
import "./auth.css"; // You can import your CSS file here
import { useNavigate } from "react-router";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { API_URL } from "../../App";

function Login() {
  const [isSignInVisible, setIsSignInVisible] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const toastFunction = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: true,
      closeButton: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      toastFunction("Fill All Credentials");
      return;
    }

    fetch(`${API_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((res) => res.json())
      .then((data) => {
        toastFunction(data.message);

        if (data.status === true) {
          if (data.user.role === "admin") {
            localStorage.setItem("user", JSON.stringify(data.user));
            localStorage.setItem("token", data.token);
            localStorage.setItem("email", JSON.stringify(data.user.email));
            localStorage.setItem("phone", JSON.stringify(data.user.mobile));
            navigate("/admin");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            return;
          } else {
            localStorage.setItem("token", data.token);
            localStorage.setItem("user", JSON.stringify(data.user));
            localStorage.setItem("email", JSON.stringify(data.user.email));
            localStorage.setItem("phone", JSON.stringify(data.user.mobile));
            navigate("/");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      })
      .catch((error) => alert(error));
  };

  const toggleForm = (e) => {
    e.preventDefault();
    setIsSignInVisible(!isSignInVisible);
    setTimeout(() => {
      navigate("/register");
    }, 1000);
  };

  const toggleLoginPasswordVisibility = () => {
    setShowLoginPassword(!showLoginPassword);
  };

  const [showLoginPassword, setShowLoginPassword] = useState(false);

  return (
    <section>
      <div className={`container ${isSignInVisible ? "" : "active"}`}>
        <div className="user signinBx">
          <div className="imgBx">
            <img src="/assets/images/auth/login.jpg" alt="login" />
          </div>
          <div className="formBx">
            <form onSubmit={handelSubmit}>
              <h2>Sign In</h2>
              <input
                type="text"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="eyeDiv">
                <input
                  className="eyeInput"
                  type={showLoginPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="eyeBtn"
                  style={{ marginTop: "-12px" }}
                  onClick={toggleLoginPasswordVisibility}
                >
                  {showLoginPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </span>
                <Link
                  style={{ fontSize: "10px", color: "blue" }}
                  to="/verify-email"
                >
                  Forgot Password
                </Link>
              </div>
              <input type="submit" value="Login" />
              <p className="signup">
                Don't have an account ?
                <a href="#" onClick={toggleForm}>
                  Sign Up.
                </a>
              </p>
            </form>
          </div>
        </div>
        {/* Additional code for signupBx */}
      </div>
    </section>
  );
}

export default Login;
