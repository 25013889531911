import { Route, Routes } from "react-router-dom"
import ConditionalPage from "./ConditionalPage"
import Page from "../component/Pages/Page"
import UserPage from "../component/Pages/userPage"
import AllPage from "../component/Pages/allPage"
import PrivateRoute from "./PrivateRoute"
import Profile from "../component/profile/Profile"
import Friend from "../component/friend/Friend"
import FriendRequest from "../component/friend-request/FriendRequest"
import Notification from "../component/notification/Notification"
import Messenger from "../component/chat/messenger/Messenger"
import { UserProfile } from "../component/userProfile/UserProfile"
import Header from "../component/heading/heading"
import { token } from "../component/home/Home"
import AdminSidebar from "../Admin/AdminSidebar"
import Register from "../component/auth/Register"
import Main from "../video_chat/Main"
import { ForgotPassword } from "../component/auth/ForgotPassword"
import { Otp } from "../component/auth/Otp"
import { VerifyEmail } from "../component/auth/VerifyEmail"
import { VerifyEmailReg } from "../component/auth/VerifyEmailReg"




const AllRoute = () => {
      const user = JSON.parse(localStorage.getItem("user"));
    
    return (
        <div className="App">
           {user?.role === "user" && token ? <Header /> : ""}

            <Routes>
                <Route path="/admin" element={<AdminSidebar />} />
                <Route path="/register" element={<Register />} />
                <Route path="/" element={<ConditionalPage />} />
                <Route path="/yourpage" element={<Page />} />
                <Route path="user/page/:id" element={<UserPage />} />
                <Route path="page/:id" element={<AllPage />} />
                <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
                <Route path="/friend" element={<PrivateRoute><Friend /></PrivateRoute>} />
                <Route path="/FriendRequest" element={<PrivateRoute><FriendRequest /></PrivateRoute>} />
                <Route path="/notification" element={<PrivateRoute><Notification /></PrivateRoute>} />
                <Route path="/message" element={<PrivateRoute><Messenger /></PrivateRoute>} />
                <Route path="/chat" element={<PrivateRoute><Messenger /></PrivateRoute>} />
                <Route path='/user/profile/:id' element={<PrivateRoute><UserProfile /></PrivateRoute>} />
                <Route path='/videoCall' element={<Main/>} />
                <Route path="/forgot/Password" element={<ForgotPassword />} />
                <Route path="/verify-otp" element={<Otp />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path='/user/email/verification' element={<VerifyEmailReg />} />
            </Routes>
        </div>
    )
}

export default AllRoute