import React, { useState } from 'react'
import { API_URL } from "../../../App"
import { token } from '../Home'
import { format } from "timeago.js";
import Dropdown from 'react-bootstrap/Dropdown';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import LikeModal from './LikeModal';
import CommentModal from './CommentModal';
import { toast } from "react-toastify";
import "./postCard.css"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function VideoCard1({ postItem }) {
    // const [data, setData] = useState([])
    const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [video, setVideo] = useState("")
    const [description, setDescription] = useState("")
    const [postId, setPostId] = useState("")
    const [newVideo, setNewVideo] = useState()
   
    let route = window.location.href.split('/')[3]


    const [toggle,setToggle]=useState(false)
    const [reportModalOpen, setReportModalOpen] = useState(false);
    const [val,setVal]=useState("")
    const [spamType,setSpamType]=useState("")
    
    const selectSpamType = (type) => {
        
        setSpamType(type);
    };

    const toastFunction = (messages
        ) => {
          return toast(messages, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeButton: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          })
        }

        const toggleReportModal = () => {
            setReportModalOpen(!reportModalOpen);
        };

    // const imagePost = () => {
    //     fetch(`${API_URL}/get/video/upload`, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Accept: "application/json",
    //             "Authorization": `Bearer ${token}`
    //         }
    //     }).then(res => res.json())
    //         .then(data => {
    //             console.log(data.message)
    //             if (data.status == true) {
    //                 setData(data.message, "video")
    //                 // setProfile(data.message)
    //                 // setProfileInformation(data.message.profile)
    //             } else {
    //                 console.log(data.message)
    //             }

    //         })
    //         .catch((err) => console.log(err))
    // }



    const editPost = (id, video, description) => {
        setPostId(id)
        setOpen(true)
        setVideo(video)
        setDescription(description)
    }

    const postDelete = (id) => {
        fetch(`${API_URL}/delete/video/upload/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => res.json())
            .then(data => {
                
            })
    }

    const savePost = () => {

        let fdata = new FormData()
        fdata.set("video", newVideo)
        fdata.set("description", description)

        fetch(`${API_URL}/update/video/upload/${postId}`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body: fdata
        }).then(res => res.json())
            .then(data => {
                if (data.status === true) {
                    window.location.reload();
                } else {
                    alert(data.message)
                }
            }).catch((err) => alert(err.message))
    }

    

    //report post funciton

    const ReportPost=(userId,postId)=>{
        fetch(`${API_URL}/spam/report?userId=${userId}&postId=${postId}`,{
            method:"POST",
            headers:{
                "Content-Type": "application/json",
                "Authorization":`Bearer ${token}`
            },
            body:JSON.stringify({spamType:spamType,description:val})
        })
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            if(data.status===true){
                toastFunction(data.message)
                setReportModalOpen(false)
            }
            else{
                alert(data.message)
            }
        })
        .catch((err) => alert(err.message))
        setSpamType("")
        setVal("")
    }



    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        {!newVideo && <video width="100%" height="100%" controls autoPlay>
                            <source src={video} type="video/mp4" />
                        </video>}
                        {newVideo && (
                            <div className="shareImgContainer">
                                <video width="640" height="360" controls autoPlay>
                                    <source src={URL.createObjectURL(newVideo)} type="video/mp4" />
                                </video>
                                <br />
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-warning" style={{ margin: "10px" }} onClick={() => setNewVideo(null)}>cancel</button>

                                </div>

                            </div>

                        )}
                        <input type="file" style={{ margin: "auto" }} onChange={(e) => setNewVideo(e.target.files[0])} /></div>



                    <div>Description</div>
                    <input type="textarea" value={description} onChange={(e) => setDescription(e.target.value)} style={{ border: '1px solid black', borderRadius: "20px", padding: "10px", height: "10vh", width: "100%" }} />
                    <br />
                    <br />
                    <div>
                        <button onClick={() => savePost()} className="btn btn-primary" style={{ alignItems: "center" }}>Save</button>

                    </div>
                </Box>
            </Modal>
            <div className="news-feed news-feed-post mb-3">
                <div className="post-header d-flex justify-content-between align-items-center" style={{position:"relative"}}>
                    <div className="image">
                        <a href="my-profile.html"><img src={`${postItem?.user?.profile ? postItem?.user?.profile?.profileImage?.url : ""}`}
                            className="rounded-circle" alt="img" style={{ width: "50px" }} /></a>
                    </div>
                    <div className="info ms-3">
                        <span className="name">{postItem?.user?.name}</span>
                        <span className="small-text">{format(postItem?.createdAt)}</span>
                    </div>
                    {route ==="" ? "" : <Dropdown style={{ border: "none" }}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ border: "none" }}>
                            <i className="fa-solid fa-light fa-caret-down" style={{ fontSize: "30px", border: 'none', padding: "5px" }}></i>

                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1" onClick={() => editPost(postItem?._id, postItem?.videoFile?.url, postItem?.description)}>Edit</Dropdown.Item>
                            <Dropdown.Item href="#/action-2" onClick={() => postDelete(postItem?._id)}>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>}
                    {
                                route===""?(
                                    <div >
                                        <i className="fa-solid fa-ellipsis" style={{cursor:"pointer",fontSize:"20px"}} value={toggle} onClick={()=>setToggle(!toggle)}></i>
                                        <div className={`spam_report ${toggle?"show_reprts":""}`}>
                                            <ul>
                                                <li className='d-flex justify-content-between align-items-center'><img src='https://static.xx.fbcdn.net/rsrc.php/v3/yE/r/noewHr__AjB.png' style={{width:"18px",marginRight:"5px"}} alt='img'/><span onClick={toggleReportModal} style={{cursor:"pointer"}}>Report post</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                ):""
                              }
                </div>
                <Modal
                open={reportModalOpen}
                onClose={toggleReportModal}
                aria-labelledby="report-modal-title"
                aria-describedby="report-modal-description"
                >
                <Box sx={style}>
                <div className='d-flex justify-content-center align-items-center report_modal'>
                    <div>
                    {/* <h2 id="report-modal-title">Report Post</h2> */}
                    <p className="report-modal-description mb-2">Please provide details about why you are reporting this post.</p>
                    <ul style={{position:"relative"}}>
                        <li className='mb-2' onClick={() => selectSpamType('Spam')}>Spam <i className="fa-solid fa-angle-right icon"></i></li>
                        <li className='mb-2' onClick={() => selectSpamType('Nudity')}>Nudity <i className="fa-solid fa-angle-right icon"></i></li>
                        <li className='mb-2' onClick={() => selectSpamType('Voilence')}>Voilence <i className="fa-solid fa-angle-right icon"></i></li>
                        <li className='mb-2' onClick={() => selectSpamType('Involves a child')}>Involves a child <i className="fa-solid fa-angle-right icon"></i></li>
                        <li className='mb-2' onClick={() => selectSpamType('Hate speech')}>Hate speech <i className="fa-solid fa-angle-right icon"></i></li>
                        <li className='mb-2' onClick={() => selectSpamType('Terrorisom')}>Terrorisom <i className="fa-solid fa-angle-right icon"></i></li>
                    </ul>
                    <div>
                        <input placeholder='report name' className='report-name mt-2' value={spamType} onChange={(e)=>setSpamType(e.target.value)} required  disabled />
                        <div className="report-page-form-group mt-3" style={{marginBottom:"12px"}} >
                    <textarea name="message" className="page-form-control"
                        placeholder="Write bio here..." value={val} onChange={(e) => setVal(e.target.value)}></textarea>
                </div>
                <button className="btn btn-primary " style={{width:"100%"}} onClick={()=>ReportPost(postItem?.user?._id,postItem?._id)}>Submit</button>
                    </div>
                    
                </div>
                </div>
                </Box>
            </Modal>
                
                <div className="post-body">
                    <p>{postItem.description}</p>
                    <div className="post-image" >

                        <video   controls autoPlay>

                            <source src={postItem?.videoFile?.url} type="video/mp4" />

                        </video>
                    </div>
                    <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                        <li className="post-react">
                            <LikeModal like={postItem?.like} postId={postItem?._id} category="video" />


                        </li>
                        <li className="post-comment">
                            <CommentModal comment={postItem?.comment} postId={postItem?._id} category="video" />
                        </li>
                        <li className="post-share">
                           <i className="flaticon-share"></i><span>Share</span> <span
                                className="number">{postItem?.share?.length ? postItem?.share?.length : "0"} </span>
                        </li>
                    </ul>

                    {/* here */}
                </div>
            </div>
        </>


    )
}


