import GetPagePost from "./GetPagePost";
import PageUpload from "./PageUpload";

const PageMain = () => {
  return (
    <div className="col-lg-6 col-md-12">
      <div className="news-feed-area">
        <PageUpload />
        <GetPagePost />
      </div>
    </div>
  );
};
export default PageMain;
