import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { GetPagesByUser } from "../../apiCall/GetAllFrindsList"
import { API_URL } from "../../App"
import { token } from "../home/Home"


const YourPage=()=>{
    const [data,setData]=useState([])


    useEffect(()=>{ 
        GetPagesByUser("managed")
        .then((data)=>{
                setData(data?.pages)
                console.log(data?.pages)
               console.log(data?.pages[0].likes.length,"dkfjk")
               localStorage.setItem("count",data?.pages[0].likes.length)
               localStorage.setItem("pageLikes",JSON.stringify(data?.pages))
        })
        .catch((err)=>{
                alert(err)
        })
    },[])

    
    const PageDelete=(id)=>{
        fetch(`${API_URL}/page/delete/${id}`,{
            method:"DELETE",
            headers:{
                "Content-Type":"application/json",
                "Authorization":`Bearer ${token}`
            }
        })
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            if(data.message){
                window.location.reload()
            }
           
        })
        

    }

    

    return (
        <div className="col-lg-6 col-md-12" style={{margin:"auto",marginTop:"90px"}}>
                            <h2 style={{fontSize:"var(--font-size)",fontWeight:"600"}} className="mb-3">Pages you manage</h2>
            <div className="my_pages ">
            {
                data?.map((ele)=>(
                    <div className="card mb-3 " style={{padding:"10px"}} key={ele._id}>
                        <div >
                        {ele.cover && ele.cover.url ? (
                        <img src={ele.cover.url} alt="img" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                        ) : (
                         <img src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1709533220~exp=1709533820~hmac=47c52625a3fedd075b20fafcd8ba89b1bd35b9c720edc01c79db142d416e1892" alt="img" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                        )}
                        
                        <div  style={{position:"absolute",left:"110px",bottom:"110px",width:"200px"}}>
                        <span style={{fontSize:"18px"}}>{ele.name}</span>
                        </div>
                        
                        <div className="mt-3 d-flex justify-content-between">
                            <button className="btn btn-primary" style={{width:"45%"}}><i className="fa-solid fa-plus"></i>{" "}<Link to={`/user/page/${ele._id}`} style={{color:"white"}}>Create Post</Link></button>
                            
                            {/* <button disabled className="btn btn-success" >Promote</button> */}
                            <button className="btn btn-warning" onClick={()=>PageDelete(ele._id)} style={{width:"45%"}}>Remove</button>
                            
                        </div>
                        </div>
                    </div>
                ))
            }
               
            </div>
        </div>
    )
}

export default YourPage