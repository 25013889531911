import { API_URL } from "../App"
import { token } from "../component/home/Home"

export const getProfile = () => {
    fetch(`${API_URL}/get/all/friend`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }
    }).then(res => res.json())
        .then(data => {
            return data.message
            // if (data.status == true) {
            //     return data
            // } else {
            //     return []
            // }
        })
        .catch((err) => {
            // console.error(err)
        })
}

// send frind request api


export const addFriend = (id) => {
    
   return fetch(`${API_URL}/send/friend/request/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        
        if (data.status === true) {
          alert("Request Send");
        }
        return data
      }

      )
      .catch((error)=>{
            // console.error(error)
      })
  }


  //get pages
  
  export const GetPagesByUser=(type = '',pageId=null)=>{
    let apiUrl=`${API_URL}/page/get?type=${type}`
    if(pageId){
      apiUrl+=`&id=${pageId}`
    }
    return fetch(apiUrl,{
      method:"GET",
      headers:{
        "Content-Type":"application/json",
        "Authorization":`Bearer ${token}`
      }
    })
    .then(res=>res.json())
  }


// Page-like function

