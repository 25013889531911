import GetPagePost from "../PagePost/GetPagePost"
import "./PageMain.css"

const PageMain=()=>{


    return (
        <div className="col-lg-6 col-md-12">
         <div className="news-feed-area">
                    <GetPagePost/>
                </div>
    </div>
    )
}

export default PageMain

// font-size: 18px;
//     position: relative;
//     color: #3644d9;
//     top: 4px;
//     margin-right: 5px;
// }