import React, { useEffect, useState } from "react";
import { API_URL } from "../../App";
import { token } from "../home/Home";
import { toast } from "react-toastify";
export default function About() {
  const [profile, setProfile] = useState();
  const [show, setShow] = useState(true);

  const [email, setEmail] = useState(profile ? profile.email : "");
  const [mobile, setMobile] = useState(profile ? profile.mobile : "");
  const [dob, setDob] = useState(profile ? profile.profile.dob : "");
  const [gender, setGender] = useState(profile ? profile.profile.gender : " ");
  const [relation, setRelation] = useState(
    profile ? profile?.profile.materialStatus : " "
  );
  const [bio, setBio] = useState(
    profile ? profile?.profile.bio : " "
  );


  const toastFunction = (messages
  ) => {
    return toast(messages, {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: true,
      closeButton: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    })
  }


  const editProfile = () => {
   
    fetch(`${API_URL}/update/profile`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email,
        mobile,
        dob,
        materialStatus: relation,
        gender,
        bio
      }),

    })
      .then((res) => res.json())
      .then((data) => {
       
        if (data.status === true) {
          toastFunction(data?.message);
          setShow(true);
        } 
      });
  };
  let getProfile = () => {
    fetch(`${API_URL}/profile`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("data,./////////", data)
        setProfile(data?.message);
        setEmail(data?.message?.email);
        setMobile(data?.message?.mobile);
        setGender(data?.message?.profile?.gender);
        setDob(data?.message?.profile?.dob);
        setRelation(data?.message?.profile?.materialStatus);
        setBio(data?.message?.profile?.bio);
        localStorage.setItem('dob', data?.message?.profile?.dob)
      });
  };

  useEffect(() => {
    getProfile();
  }, []);
  return (
    <div className="row">
      <div className="col-lg-3 col-md-12">
        <div className="about-personal-information">
          <div className="about-header d-flex justify-content-between align-items-center">
            <div className="title">Personal Information</div>
            <div className="dropdown">
              {show && (
                <i style={{ cursor: "pointer" }}
                  className="fa-solid fa-pen-to-square"
                  onClick={() => setShow(!show)}
                ></i>
              )}
              {!show && (
                <i
                  className="fa-solid fa-xmark"
                  onClick={() => setShow(true)}
                ></i>
              )}
            </div>
          </div>
          {show && (
            <ul className="information-list">
              <li>
                <span>Email:</span>{" "}
                <a href="https://templates.envytheme.com/cdn-cgi/l/email-protection#f990979f96b9838c8a8dd79a9694">
                  <span
                    className="__cf_email__"
                    data-cfemail="dab3b4bcb59aa0afa9aef4b9b5b7"
                    style={{ color: "gray", fontWeight: 400 }}
                  >
                    {profile ? profile.email : "NA"}
                  </span>
                </a>
              </li>
              <li>
                <span>Birthday:</span> {profile ? profile?.profile?.dob : "NA"}
              </li>
              <li>
                <span>Phone:</span>{" "}
                <a href="tel:916-879-7755">{profile?.mobile ? profile.mobile : "NA"}</a>
              </li>
              <li>
                <span>Gender:</span> {profile?.profile.gender ? profile.profile.gender : "NA"}
              </li>
              <li>
                <span>Relationship Status:</span>{" "}
                {profile?.profile.materialStatus ? profile.profile.materialStatus : "NA"}
              </li>
            </ul>
          )}
          {!show && (
            <ul className="information-list">
              <li>
                <div style={{ fontWeight: "bold" }}>Email *</div>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    border: "2px solid black",
                    borderRadius: "20px",
                    textAlign: "center",
                    padding: "5px",
                    width: "100%",
                  }}
                />
              </li>
              <li>
                <div style={{ fontWeight: "bold" }}>Birthday *</div>
                <input
                  type="date"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  style={{
                    border: "2px solid black",
                    borderRadius: "20px",
                    textAlign: "center",
                    padding: "5px",
                    width: "100%",
                  }}
                />
              </li>

              <li>
                <div style={{ fontWeight: "bold" }}>Phone *</div>
                <input
                  type="text"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  style={{
                    border: "2px solid black",
                    borderRadius: "20px",
                    textAlign: "center",
                    padding: "5px",
                    width: "100%",
                  }}
                />
              </li>
              <li>
                <div style={{ fontWeight: "bold" }}>Gender *</div>
                <input
                  type="text"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  style={{
                    border: "2px solid black",
                    borderRadius: "20px",
                    textAlign: "center",
                    padding: "5px",
                    width: "100%",
                  }}
                />
              </li>
              <li>
                <div style={{ fontWeight: "bold" }}>Relationship Status *</div>
                <input
                  type="text"
                  value={relation}
                  onChange={(e) => setRelation(e.target.value)}
                  style={{
                    border: "2px solid black",
                    borderRadius: "20px",
                    textAlign: "center",
                    padding: "5px",
                    width: "100%",
                  }}
                />
              </li>
              <li>
                <div style={{ fontWeight: "bold" }}>About me *</div>
                <textarea
                  type="text"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  style={{
                    border: "2px solid black",
                    borderRadius: "20px",
                    textAlign: "center",
                    padding: "5px",
                    width: "100%",
                  }}
                />
              </li>
              <li>
                <button
                  className="btn btn-primary"
                  onClick={() => editProfile()}
                >
                  Save
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="col-lg-9 col-md-12">
        <div className="about-details-information">
          <div className="information-box-content">
            <div className="information-header d-flex justify-content-between align-items-center">
              <div className="title">About Me!</div>
            </div>
            <div className="content">
              <p>{profile?.profile?.bio ? profile.profile.bio : "NA"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
