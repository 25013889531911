import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { API_URL } from '../../../App';
import { token } from '../Home';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function LikeModal({like,postId,category}) {
    const [open, setOpen] = React.useState(false);
    const [likes , setLikes]=React.useState(like.length)
   

    
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const likePost = (id) => {

        fetch(`${API_URL}/like/${category}/post/${id}`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${token}`
            },

        }).then(res => res.json())
            .then(data => {
                if (data.status === true) {
                    
                    setLikes(data.like.length)
                   
                   
                } else {
                    alert(data.message)
                }
            })
            .catch((err) => alert(err.message))
           
    }
    

   
    return (
        <div>
            <Button >
                <a onClick={() => likePost(postId)}><i className="flaticon-like" ></i><span >Like</span>
                </a> <span onClick={handleOpen}
                    className="number">{
                        
                    likes 
                    } </span>
            </Button>
          
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                   {like && like.map((item)=>(
                    
                           <div style={{ display: "flex", justifyContent: "left", alignItems: "center", padding:"5px"}} key={item._id}>
                               <img src={item?.profile?.profileImage?.url} alt='profile image' style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "17px" }} />
                               <div>
                                   <h5 style={{ color: "black", fontWeight: "bold" }}>{item.name}</h5>
                               </div>
                           </div>
                    
                   ))}
                </Box>
            </Modal>
        </div>
    );
}
// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import { API_URL } from '../../../App';
// import { token } from '../Home';
// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

// export default function LikeModal({like,postId,category}) {
//     const [open, setOpen] = React.useState(false);
//     const handleOpen = () => setOpen(true);
//     const handleClose = () => setOpen(false);
//     const likePost = (id) => {

//         fetch(`${API_URL}/like/${category}/post/${id}`, {
//             method: "PUT",
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },

//         }).then(res => res.json())
//             .then(data => {
//                 if (data.status == true) {
//                     console.log(data)
//                 } else {
//                     alert(data.message)
//                 }
//             }).catch((err) => alert(err.message))

//     }

//     return (
//         <div>
//             <Button >
//                 <a onClick={() => likePost(postId)}><i className="flaticon-like"></i><span >Like</span>
//                 </a> <span onClick={handleOpen}
//                     className="number">{like.length} </span>
//             </Button>
//             <Modal
//                 open={open}
//                 onClose={handleClose}
//                 aria-labelledby="modal-modal-title"
//                 aria-describedby="modal-modal-description"
//             >
//                 <Box sx={style}>
//                    {like && like.map((item)=>(
//                     <>
//                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
//                                <img src={`${API_URL}/api/v1/blog/${item.profile.profileImage}`} style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "17px" }} />
//                                <div>
//                                    <h5 style={{ color: "black", fontWeight: "bold" }}>{item.name}</h5>
//                                </div>
//                            </div>
//                     </>
//                    ))}
//                 </Box>
//             </Modal>
//         </div>
//     );
// }