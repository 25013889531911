import { useEffect, useRef, useState } from 'react';
import Heading from '../heading/heading'
import { useParams } from 'react-router-dom';
import PageRight from './PageWidgets/PageRight';
import "./userPage.css"
import { token } from '../home/Home';
import { API_URL } from '../../App';
import { toast } from "react-toastify";
import PageLeft from './PageWidgets/PageLeft';
import PageMain from './PageWidgets/PageMain';
import { useDispatch, useSelector } from "react-redux"
import { GetCommonPage } from '../../Page-redux/commonPageReducer/action';



const AllPage = () => {
    const [showTab, setShowTab] = useState("timeline");
    // const [profilePic, setProfilePic] = useState("")
    const likeVal = JSON.parse(localStorage.getItem("likeCounts"))||[]
    const [count, setCount] = useState(0)
    const [likes, setLikes] = useState(count);
    const inputRef = useRef(null);
    const [newImage, setNewImage] = useState()
    const { id } = useParams()
    const [isLiked, setIsLiked] = useState(!!count.length);

    const [val, setVal] = useState("")

    let dispatch = useDispatch()

    let userPage=useSelector((item)=>item.commonPageReducer.data)

    const toastFunction = (message) => {
        toast(message, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeButton: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }



    const handleClick = () => {
        inputRef.current.click();
    };


    useEffect(() => {
        dispatch(GetCommonPage(id))
    }, [id])



    useEffect(() => {
        const existingLikes = likeVal?.find((ele) => ele._id === userPage?.map((page) => page._id).join(""));
        setCount(existingLikes?.likes.length || 0);

        setLikes(existingLikes?.likes.length || 0)
    }, [userPage])



    useEffect(() => {
        const checkIfLikedByAdmin = () => {
            if (!userPage || !userPage[0] || !userPage[0].page_admin_id) {
                return undefined; // Handle empty or missing data
            }

            const likedByAdmin = likeVal.find((like) =>
                like.likes.some((item) => item._id === userPage[0].page_admin_id._id)
            );

            return likedByAdmin;
        };

        const likedItem = checkIfLikedByAdmin();
    }, [userPage]);

    //page like funciton 

    const toggleLike = async (_id) => {
        const url = isLiked ? `${API_URL}/page/dislike/${_id}` : `${API_URL}/page/like/${_id}`;
        const method = isLiked ? 'PUT' : 'PUT'; // Adjust method based on like state

        try {
            const response = await fetch(url, {
                method,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const data = await response.json();
            toastFunction(data.message);
            setVal(data.message)
            if (data.status === true) {

                setLikes(data.data.length);
                setIsLiked(!isLiked); // Update like state based on response
            }
        } catch (error) {
            alert('Error liking/unliking post:', error);
        }
    };




    return (
        <div className=" d-flex flex-column main-content-wrapper">
            {/* <Heading /> */}

            <div className="content-page-box-area">
                <div className="my-profile-inner-box">
                    <div className="profile-cover-image cover_image" >
                        {/* <a href="#">
                                <img src="" alt="image" style={{height:"100%"}}/>
                            </a> */}
                        <span className="edit-cover-btn">Edit Cover</span>
                    </div>
                    <div className="profile-info-box">
                        <div className="inner-info-box d-flex justify-content-between align-items-center">
                            <div className="info-image">
                                {
                                    userPage && userPage.map((ele) => (
                                        <div key={ele._id}>
                                            {ele.cover && ele.cover.url ? (
                                                <img src={ele.cover.url} alt="img" style={{ width: "200px", height: "200px", borderRadius: "50%" }} />
                                            ) : (
                                                <img src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1709533220~exp=1709533820~hmac=47c52625a3fedd075b20fafcd8ba89b1bd35b9c720edc01c79db142d416e1892" alt="img" style={{ width: "200px", height: "200px", borderRadius: "50%" }} />
                                            )}
                                            <div className="icon" style={{ position: "absolute", left: "95px", bottom: "5px" }}>
                                                <input
                                                    style={{ display: 'none' }}
                                                    ref={inputRef}
                                                    type="file"
                                                    onChange={(e) => {
                                                        setNewImage(e.target.files[0])
                                                        // console.log("set image .......", e.target.files[0].name)
                                                    }}
                                                    accept="image/png, image/jpeg"
                                                />
                                                {!ele.cover && <i className="flaticon-photo-camera" onClick={handleClick}></i>}
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                            <div className="info-text ms-3">
                                {
                                    userPage && userPage.map((ele) => (
                                        <div key={ele._id}>
                                            <h3  key={ele._id} style={{color:"black",fontSize:"18px",fontWeight:"600"}}>{ele.name}</h3>
                                            <span>{likes} likes | {likes} followers</span>
                                        </div>
                                    ))
                                }

                            </div>
                            {
                                userPage && userPage.map((ele) => (
                                    <ul className="statistics" key={ele._id}>
                                        <li>

                                            <button className='btn btn-secondary' onClick={() => toggleLike(ele._id)}><i className="fa-solid fa-thumbs-up" ></i>{" "}{isLiked ? "Liked" : "Like"}</button>
                                        </li>
                                        <li>

                                            <button className='btn btn-primary'><i className="fa-brands fa-facebook-messenger"></i>{" "}Message</button>
                                        </li>
                                        {/* <li>
                                
                                            <button className='btn btn-secondary'><i className="fa-solid fa-magnifying-glass"></i>{" "}Search</button>
                                        </li> */}
                                    </ul>
                                ))
                            }

                        </div>
                        <div className="profile-list-tabs">
                            <ul className="nav nav-tabs" id="myTab" >
                                <li className="nav-item">
                                    <span className={`nav-link ${showTab === "timeline" ? "active" : ""}`} id="timeline-tab" data-bs-toggle="tab" role="tab" aria-controls="timeline" onClick={() => setShowTab("timeline")}> Posts</span>
                                </li>
                                <li className="nav-item">
                                    <span className={`nav-link ${showTab === "about" ? "active" : ""}`} id="about-tab" data-bs-toggle="tab" role="tab" aria-controls="about" onClick={() => setShowTab("about")}>About</span>
                                </li>
                                <li className="nav-item">
                                    <span className={`nav-link ${showTab === "friends" ? "active" : ""}`} id="friends-tab" data-bs-toggle="tab" role="tab" aria-controls="friends" onClick={() => setShowTab("friends")}>Mentions</span>
                                </li>
                                <li className="nav-item">
                                    <span className={`nav-link ${showTab === "photos" ? "active" : ""}`} id="photos-tab" data-bs-toggle="tab" role="tab" aria-controls="photos" onClick={() => setShowTab("photos")}>Reviews</span>
                                </li>
                                <li className='nav-item'>
                                    <span className={`nav-link ${showTab === "pages" ? "active" : ""}`} id="photos-tab" data-bs-toggle="tab" role="tab" aria-controls="pages" onClick={() => setShowTab("pages")}>Followers</span>
                                </li>
                                <li className="nav-item">
                                    <span className={`nav-link ${showTab === "" ? "active" : ""}`} id="photos-tab" data-bs-toggle="tab" role="tab" aria-controls="photos"
                                    // console.log("clear local storage ...")
                                    >Photos</span>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div className="tab-content" id="myTabContent">
                    <div className={`tab-pane fade ${showTab === "timeline" && "show active"} `} role="tabpanel" >
                        <div className="row">
                            <PageRight />
                            <PageMain />
                            <PageLeft />
                        </div>
                    </div>

                    <div className={`tab-pane fade ${showTab === "about" && "show active"} `} role="tabpanel" onClick={() => setShowTab("about")}>
                        about
                    </div>

                    <div className={`tab-pane fade ${showTab === "friends" && "show active"} `} role="tabpanel" onClick={() => setShowTab("friends")}>
                        friend
                    </div>
                    <div className={`tab-pane fade ${showTab === "pages" && "show active"} `} role="tabpanel" onClick={() => setShowTab("pages")}>
                        page
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllPage














                        {/* {!newImage &&
                                        <img src={profilePic ? `${profilePic}` : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1709533220~exp=1709533820~hmac=47c52625a3fedd075b20fafcd8ba89b1bd35b9c720edc01c79db142d416e1892"} alt="image" style={{ width: "200px", height: "200px",borderRadius:"50%" }} />
                                    }
                                    {newImage &&
                                        <img src={URL.createObjectURL(newImage)} alt="image" style={{ width: "200px", height: "200px" }} />

                                    }
                                    {newImage &&
                                        <button type="button" className="btn btn-primary" style={{ color: "black" }} >Save</button>
                                    }
                                    <div className="icon" style={{position:"absolute",left:"95px",bottom:"5px"}}>
                                        <input
                                            style={{ display: 'none' }}
                                            ref={inputRef}
                                            type="file"
                                            onChange={(e) => {
                                                setNewImage(e.target.files[0])
                                                // console.log("set image .......", e.target.files[0].name)
                                            }}
                                            accept="image/png, image/jpeg"
                                        />
                                        {!newImage && <i className="flaticon-photo-camera" onClick={handleClick}></i>}
                                    </div> */}