
import { useNavigate } from "react-router-dom";
import './auth.css';
import { toast } from "react-toastify";
import { API_URL } from "../../App";
import { useState } from "react";

export const VerifyEmailReg = () => {
    const localEmail = localStorage.getItem("email");
  
    const [otp, setOtp] = useState('')
    const [email, setEmail] = useState('')
    const [emailShow, setEmailShow] = useState(false);
    const navigate = useNavigate();
    var count = 0;
    const toastFunction = (messages
    ) => {
        return toast(messages, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeButton: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        })
    }


    const handelSubmit = (e) => {
        e.preventDefault();
        
        fetch(`${API_URL}/verify-OTP`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: JSON.stringify({ otp, email: localEmail })
        })
            .then((res) => res.json())
            .then((data) => {
                
                if (otp.length == "") {
                    toastFunction('Enter OTP')
                }
                else if (data.status == false) {
                    count++;
                    if (count == 3) {
                        toastFunction(data.message);
                        setEmailShow(!emailShow);
                    } else {
                        return
                    }
                }
                else {
                    toastFunction(data.message)
                    navigate("/")
                }
            });
    }


    const handelSubmitOtp = (e) => {
        e.preventDefault();
        fetch(`${API_URL}/send-OTP`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: JSON.stringify({ email })
        })
            .then((res) => res.json())
            .then((data) => {
               
                setEmailShow(!emailShow)
                toastFunction('OTP Send On Email')
            });
    }
    return (
        <>
            <body>
                <section>
                    <div className="container row align-items-center justify-content-center user" style={{ height: "350px" }} >
                        <div className="card-body">
                            {!emailShow ? <>
                                <div className="mb-4">
                                    <h5 >Verify OTP</h5>
                                </div>
                                <form action="" onSubmit={(e) => handelSubmit(e)}>
                                    <div className="mb-3">
                                        <label for="otp" className="form-label" style={{ fontSize: "15px", color: "gray" }}>OTP</label>
                                        <input type="" id="otp" className="form-control" name="otp" placeholder="Enter Your OTP" onChange={(e) => setOtp(e.target.value)}
                                            required="" />
                                    </div>
                                    <input className="resetBtn" type="submit" name="" value={"Enter OTP"} />
                                </form>
                            </>
                                : <>
                                    <div className="mb-4">
                                        <h5 >Enter Email</h5>
                                    </div>
                                    <p className="mb-2 " style={{ fontSize: "15px", paddingTop: "10px", color: "gray" }}>Enter your registered email ID
                                    </p>
                                    <form action="" onSubmit={(e) => handelSubmitOtp(e)}>
                                        <div className="mb-3">
                                            <label for="email" className="form-label" style={{ fontSize: "15px", color: "gray" }}>Email</label>
                                            <input type="email" id="email" className="form-control" name="email" placeholder="Enter Your Email" onChange={(e) => setEmail(e.target.value)}
                                                required="" />
                                        </div>
                                        <input className="resetBtn" type="submit" name="" value={"Enter Email"} />
                                    </form>
                                </>}
                        </div>
                    </div>
                </section>
            </body>
        </>
    )
}
