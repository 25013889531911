import "./messenger.css";
import Conversation from "../conversations/Conversation";
import Message from "../message/Message";
import {  useEffect, useRef, useState } from "react";
import axios from "axios";
import { io } from "socket.io-client";
import { API_URL } from "../../../App";
import { useNavigate } from "react-router-dom";
import Heading from "../../heading/heading";
import CallIcon from "@material-ui/icons/Call";
import { Toast } from "primereact/toast";
import { Button } from "@mui/material";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import ringtoneSound from "./OtherAssets/marimba_soft.mp3"
import "react-toastify/dist/ReactToastify.css";
import "./chat.css";
import { useSelector } from "react-redux";
import { useHMSActions } from "@100mslive/react-sdk";



export default function Messenger() {

  const navigate = useNavigate();
  const [conversations, setConversations] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [senderProfilePicture, setSenderProfilePicture] = useState(null);
  const [receiverProfilePicture, setReceiverProfilePicture] = useState(null);
  const [ringtone] = useState(new Audio(ringtoneSound));
  const socket = io(`${API_URL}`);
  const user = JSON.parse(localStorage.getItem("user"));
  const hmsActions = useHMSActions();
  const [call, setCall] = useState(false);
  const [callRoomId, SetcallRoomId] = useState("")
  const [callTimerId, setCallTimerId] = useState(null)

  const item = useSelector((ele) => ele.commonPageReducer.userData)

  console.log(item, "checking item value")

  const scrollRef = useRef();

  console.log("current", currentChat)


  useEffect(() => {
    socket.on("getMessage", (data) => {
      console.log("message event called")
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });
  }, []);

  useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    socket.emit("addUser", user?._id);
    socket.on("getUsers", (users) => {
    });
  }, [user]);

  useEffect(() => {
    const getConversations = async () => {
      try {
        const res = await axios.get(`${API_URL}/api/conversations/${user?._id}`);
        setConversations(res.data);
        console.log("convo", res.data)
      } catch (err) {
        console.error(err);
      }
    };
    getConversations();
  }, [user?._id]);



  useEffect(() => {
    const getMessages = async () => {
      try {
        const res = await axios.get(
          `${API_URL}/api/messages/${currentChat?._id}`
        );
        setMessages(res.data);
      } catch (err) {
        alert(err);
      }
    };
    getMessages();
  }, [currentChat]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const message = {
      sender: user?._id,
      text: newMessage,
      conversationId: currentChat?._id,
    };

    const receiverId = currentChat.members.find(
      (member) => member !== user?._id
    );

    socket.emit("sendMessage", {
      senderId: user?._id,
      receiverId,
      text: newMessage,
    });

    try {
      const res = await axios.post(`${API_URL}/api/messages`, message);
      setMessages([...messages, res.data]);
      setNewMessage("");
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);


  //Api call to fetch user profile image.............................
  useEffect(() => {
    // Fetch sender's profile picture
    const fetchSenderProfilePicture = async () => {
      try {
        const senderProfile = await axios.get(
          `${API_URL}/api/users?userId=${user?._id}`
        );
        // console.log("sender profile", senderProfile.data);
        setSenderProfilePicture(senderProfile.data);
      } catch (error) {
        console.error(error);
      }
    };

    // Fetch receiver's profile picture when there is a current chat
    if (currentChat) {
      console.log(currentChat,"currentChat")
      const receiverId = currentChat.members.find(
        (member) => member !== user?._id
      );
      const fetchReceiverProfilePicture = async () => {
        try {
          const receiverProfile = await axios.get(
            `${API_URL}/api/conversations/${user?._id}`
          );
          console.log(receiverProfile.data);
          setReceiverProfilePicture(receiverProfile.data);
        } catch (error) {
          console.error(error);
        }

      };

      // Fetch sender and receiver profile pictures only once
      if (!senderProfilePicture) {
        fetchSenderProfilePicture();
      }

      if (!receiverProfilePicture) {
        fetchReceiverProfilePicture();
      }
    }
  }, [currentChat, user?._id, senderProfilePicture, receiverProfilePicture]);



  useEffect(() => {
    socket.emit("userId", user?._id);
  }, [])

  useEffect(() => {
    socket.on('notificationForCallerEmit', (data) => {
      console.log(data, "Room Name is Checking")

      const timerId = setTimeout(() => {
        setCall(false)
        SetcallRoomId("")
      }, 30000);

      console.log(timerId, "data")
      setTimeout(() => {
        if (data) {
          setCall(true)
          SetcallRoomId(data)
          setCallTimerId(timerId)
        }
      }, 500)

    })

  }, [])

  const callUser = (usrInfo) => {
    console.log(usrInfo, "check")
    const roomName = user?.name + '-to-call-' + usrInfo.name;
    const userToCall = usrInfo._id;
    const payload = {
      roomName: roomName,
      userToCall: userToCall
    }
    socket.emit("notificationForCaller", payload);

    navigate('/videoCall?userName=' + roomName);

  }


  const AnswerCall = () => {
    console.log(callRoomId, "roomId checking");
    clearTimeout(callTimerId)
    setTimeout(() => {
      navigate('/videoCall?userName=' + callRoomId);
    }, 100)
  }


  const DeclineCall = () => {
    clearTimeout(callTimerId)
    hmsActions.leave()
    setCall(false)
    SetcallRoomId("")
  }




  return (
    <>
      {/* .................... */}
      <div class="main-content-wrapper d-flex flex-column">
        {
          call && (
            <div>
              <div className="fixed top-0 left-0 right-0 z-50 bg-gray-600 bg-opacity-50 overflow-y-auto h-screen flex justify-center items-center">
                <div className="bg-gray-800 rounded-lg p-4 shadow-md px-8 py-5" >
                  <h3 className="text-lg font-medium mb-4 text-gray-200 text-center relative bottom-6">Incoming Call</h3>
                  <p className="text-gray-200 mb-6">
                    Are you want to answer the call from {callRoomId.split("-")[0]} ?
                  </p>
                  <div className="flex justify-between">
                    <button
                      onClick={AnswerCall}
                      className="text-white hover:text-gray-200  font-medium rounded-lg text-sm px-4 py-2.5 text-center dark:bg-green-700"
                    >
                      Answer
                    </button>
                    <button
                      className="text-white bg-red-600 hover:text-gray-200  font-medium rounded-lg text-sm px-4 py-2.5 text-center"
                      onClick={DeclineCall}
                    >
                      Decline
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        <div className="chatsMainContainer">
          <div id="frame">
            <div id="sidepanel">
              <div id="contacts">
                {conversations.map((c) => (
                  <ul onClick={() => setCurrentChat(c)}>
                    <Conversation
                      conversation={c}
                      currentUser={user}
                    // setPassUserName={setPassUserName}
                    />
                  </ul>
                ))}
              </div>
            </div>
            {currentChat ? (
              <div className="content">
                <div className="contact-profile" style={{ display: "flex" }}>
                  <div style={{ flex: 5 }}>
                    {" "}
                    <img
                      src={
                        receiverProfilePicture?.profile?.profileImage?.url ?? ""
                      }
                      className="conversationImg"
                      style={{
                        width: "30px",
                        height: "30px",
                        marginTop: "12px",
                      }}
                      alt="image error"
                    />
                    <p
                      style={{
                        marginTop: "10px",
                      }}
                      className="text-black"
                    >
                      {receiverProfilePicture?.name}
                    </p>
                  </div>
                  <div
                    className="social-media"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flex: 1,
                    }}
                  >
                    <CameraAltIcon
                      className="max-sm:relative max-sm:top-[6px] max-sm:right-9"
                      style={{
                        width: "30px",
                        height: "30px",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => callUser(item)}
                    />
                    <CallIcon
                      className="max-sm:relative max-sm:top-[6px] max-sm:right-4"
                      style={{
                        width: "30px",
                        height: "30px",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
                <div className="messages">
                  {messages.map((m) => (
                    <div ref={scrollRef}>
                      <Message
                        message={m}
                        own={m.sender === user?._id}
                        // image={friendDetails?.profile?.profileImage.url ?? ""}
                        senderProfileImg={senderProfilePicture}
                        receiverProfileImg={receiverProfilePicture}
                      />
                    </div>
                  ))}
                </div>
                <div className="message-input">
                  <div className="wrap">
                    <input
                      type="text"
                      className="text-white"
                      placeholder="Write your message..."
                      onChange={(e) => setNewMessage(e.target.value)}
                      value={newMessage}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          handleSubmit(e);
                        }
                      }}
                    />
                    <i
                      className="fa fa-paperclip attachment"
                      aria-hidden="true"
                    ></i>
                    <button className="submit" onClick={handleSubmit} disabled={newMessage == ""}>
                      <i className="fa fa-paper-plane" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <span className="noConversationText">Let's talk</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

