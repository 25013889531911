import { Navigate } from "react-router-dom"
import { token } from "../component/home/Home"

const PrivateRoute=({children})=>{

   

    if(!token){
        return <Navigate to="/"/>
    }

    return (
        children
    )

}

export default PrivateRoute