import Login from "../component/auth/Login"
import Home, { token } from "../component/home/Home"


const ConditionalPage = () => {



    return (
        <div>
            {
                token ? <Home /> : <Login />
            }
        </div>
    )
}

export default ConditionalPage