import axios from "axios";
import { useEffect, useState } from "react";
import "./conversation.css";
import "../messenger/chat.css";
import { API_URL } from "../../../App";
import { token } from "../../home/Home";
import { useDispatch } from "react-redux";
import { GetUserData } from "../../../Page-redux/commonPageReducer/action";
export default function Conversation({ conversation, currentUser }) {
  const [user, setUser] = useState(null);
  // const [friendList, setFriendList] = useState([]);
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  let dispatch=useDispatch()

  let friendId;
  if (conversation.members[0] === currentUser._id) {
    friendId = conversation.members[1];
  } else {
    friendId = conversation.members.find((m) => m !== currentUser._id);
  }

 
  const getUser = async () => {
    try {
      const res = await axios.get(`${API_URL}/api/users?userId=${friendId}`);
      setUser(res.data);
      dispatch(GetUserData(res.data))
      console.log(res.data,"data")
    } catch (err) {
      console.error(err);
    }
  };

  
  useEffect(() => {
     getUser();
  }, [])

  

  return (
    
    <li className="contact">
      <div className="wrap">
        <span className="contact-status online"></span>
        <img
          className="conversationImg"
          src={
            user
              ? `${user?.profile?.profileImage?.url}`
              : "https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png"
          }
          alt="image_error"
        />
        <div className="meta">
          <p className="name text-gray-200">{user?.name}</p>
          {/* <p class="preview">You just got LITT up, Mike.</p> */}
        </div>
      </div>
    </li>
  );
}

