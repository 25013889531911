import { useEffect, useRef, useState } from "react";
import AdminHeader from "./AdminHeader";
import Chart from "chart.js/auto";
import { API_URL } from "../App";
import { token } from "../component/home/Home";

const Analysis = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [activeValue, setActiveValue] = useState([]);

  useEffect(() => {
    fetch(`${API_URL}/active`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setActiveValue(data.activeUsers);
      });
  }, []);

  useEffect(() => {
    if (activeValue.length === 0) return;

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const myChartRef = chartRef.current.getContext("2d");
    const dates = activeValue.map((entry) => entry.updatedAt.substring(0, 10));
    const uniqueDates = [...new Set(dates)]; // Removing duplicates
    const activeUsersByDate = uniqueDates.map((date) => {
      return activeValue.filter((entry) => entry.updatedAt.startsWith(date))
        .length;
    });

    chartInstance.current = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: uniqueDates,
        datasets: [
          {
            label: "Active Users",
            data: activeUsersByDate,
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [activeValue]);

  return (
    <div>
      <AdminHeader />
      <div className="user_analysis mt-5 mb-3">
        <h3 className="text-center mb-3" style={{ fontWeight: "600" }}>
          Active User Analysis
        </h3>
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default Analysis;
