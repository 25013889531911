import { useEffect, useState } from "react";
import { GetPagesByUser } from "../../../apiCall/GetAllFrindsList";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../../Page-redux/commonPageReducer/action";


const PageRight = () => {

    const [pageData, setPageData] = useState(null);
    const [userPage,setUserPage]=useState(null)
    const { id } = useParams()
    let dispatch=useDispatch()
    let user=useSelector((item)=>item.commonPageReducer.current)
    console.log(user,"pro")
   

    useEffect(() => {
        GetPagesByUser("all", id)
            .then((data) => {
                if (data && data.pages && data.pages.length > 0) {
                    setPageData(data.pages[0]);

                }
                console.log(data,"jnsdfuw")
            })
            .catch((err) => {
                alert(err);
            });
    }, [id]);

    useEffect(()=>{
        GetPagesByUser("managed",id)
        .then((data)=>{
            if (data && data.pages && data.pages.length > 0) {
                setUserPage(data.pages[0])
            }
            
            console.log(data,"yyyy")
        })
        .catch((err)=>{
            alert(err)
        })
        localStorage.setItem("pageId",id)
      
    },[id])

    useEffect(()=>{
        dispatch(getCurrentUser())
    },[])


    return (
        <>
            <div className="col-lg-3 col-md-12">
                <aside className="widget-area" style={{ backgroundColor: "white" }}>
                    <div className="about" style={{ padding: "25px" }}>
                        <h4 className="pb-3" style={{ fontWeight: "600" }}>Intro</h4>
                        <p style={{ borderBottom: "0.5px solid grey", color: "black" }} className="text-center pb-2">{user?._id === userPage?.page_admin_id?._id? userPage?.description:pageData?.description}</p>
                    </div>
                    <div className="intro_section" >
                       
                            <ul style={{ padding: "25px" }}>
                                <li className="mb-3"><i className="fa-brands fa-product-hunt"></i>{" "}Product/service</li>
                                {
                                    user?._id === userPage?.page_admin_id?._id && (
                                        <span>
                                            <li className="mb-3"><i className="fa-solid fa-phone"></i>{" "}{userPage?.page_admin_id && userPage?.page_admin_id.mobile}</li>
                                            <li className="mb-3" ><i className="fa-solid fa-envelope"></i>{" "}{userPage?.page_admin_id && userPage?.page_admin_id.email}</li>
                                        </span>

                                    )
                                }

                                <li ><i className="fa-solid fa-star"></i>{" "}0 Reviews</li>
                            </ul>
                    
                    </div>
                </aside>
            </div>
        </>
    );
}

export default PageRight;





// const email_val=localStorage.getItem("email")
// const phone=localStorage.getItem("phone")
// const formattedPhone = phone ? phone.replace(/"/g, '') : '';
// const formattedEmail = email_val ? email_val.replace(/"/g, '') : '';