import React, { useEffect, useState } from 'react'
import { GetPagesByUser } from '../../../apiCall/GetAllFrindsList'
import { Link } from 'react-router-dom'

export default function Like() {
    const [data,setData]=useState([])
    const [likeCounts, setLikeCounts] = useState([]);

    useEffect(()=>{
        GetPagesByUser("all")
        .then((data)=>{
            setData(data?.pages)
        setLikeCounts(data?.pages); // Update likeCounts state with object
        })
        .catch((err)=>{
                console.error(err)
        })
    },[])

    useEffect(() => {
        // Store likeCounts object in localStorage on state update
        localStorage.setItem('likeCounts', JSON.stringify(likeCounts));
      }, [likeCounts]);
    return (
        <div className="widget widget-page-you-like">
            <h3 className="widget-title">Page You May Like</h3>
            {
                data && data.map((ele)=>(
                    <article key={ele._id} className="item">
                        <span  className="thumb">
                   <Link to={`/page/${ele._id}`}><span className="fullimage bg1" role="img" ><img src={ele.cover && ele.cover.url} alt="img"/></span></Link> 
                    </span>
                        <div className="info">
                    <h4 className="title">
                       <Link to={`/page/${ele._id}`}>{ele.name && ele.name}</Link> 
                    </h4>
                    <span>{ele.likes?.length} Members</span>
                </div>
                    </article>
                ))
            }
            
        </div>
    )
}
