import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { API_URL } from "../../../App";
import { useState } from "react";
import { token } from "../Home";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function CommentModal({ comment, postId, category }) {
  const [open, setOpen] = React.useState(false);
  const [comments, setComments] = useState(comment.length);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [message, setMessage] = useState();

  const commentImagePost = (id) => {
    fetch(`${API_URL}/comment/${category}/post/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ message }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setComments(data?.comments?.length);
          setMessage("");
          handleClose();
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => alert(err.message));
  };

  return (
    <div>
      <Button onClick={handleOpen} className="comment_section">
        {" "}
        <a>
          <i className="flaticon-comment"></i>
          <span>Comment</span> <span className="number">{comments} </span>
        </a>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{ maxHeight: "60vh", overflow: "scroll" }}
            className="scrollb"
          >
            {comment &&
              comment.map((item) => (
                <div key={item._id}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={`${item?.friend?.profile?.profileImage?.url}`}
                      alt="profileImage"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        marginRight: "17px",
                      }}
                    />
                    <div>
                      <h5 style={{ color: "black", fontWeight: "bold" }}>
                        {item?.friend?.name}
                      </h5>
                    </div>
                  </div>
                  <div style={{ width: "85%", marginLeft: "50px" }}>
                    <div
                      style={{
                        textAlign: "left",
                        textTransform: "capitalize",
                        fontSize: "13px",
                      }}
                    >
                      {item?.message}
                    </div>
                  </div>
                  <br></br>
                </div>
              ))}
          </div>
          <br />
          <div
            className="d-flex scrollb"
            style={{
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <textarea
              style={{
                width: "90%",
                borderRadius: "20px",
                border: "2px solid grey",
                overflowY: "hidden",
                textAlign: "center",
              }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div
              style={{
                width: "10%",
                borderRadius: "20px",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                src="assets/images/message_arrow.png"
                alt="post"
                onClick={() => commentImagePost(postId)}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
