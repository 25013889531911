import { API_URL } from "../../App";
import { token } from "../home/Home";
import "./CreatePage.css"
import { useRef, useState } from "react";
import { toast } from "react-toastify";


const CreatePage=()=>{

    const inputRef = useRef(null);
    const [newImage, setNewImage] = useState(null)
    const [name,setPageName]=useState("")
    const [description,setDescription]=useState("")

    const toastFunction = (messages
        ) => {
          return toast(messages, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeButton: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          })
        }
    
    const handleClick = () => {
        inputRef.current.click();
    };

    const PageSubmit=(e)=>{
        e.preventDefault()
        let fdata=new FormData()
        fdata.append("name",name)
       fdata.append("description",description)
      if(newImage){
        fdata.append("cover", newImage);
      }
       
        fetch(`${API_URL}/page/create`,{
            method:"POST",
            headers:{
                "Authorization":`Bearer ${token}`
            },
            body:fdata
        })
        .then(res => res.json())
        .then((data)=>{
            toastFunction(data.message)
           
            if (data.status == true) {
                setTimeout(()=>{
                    window.location.reload()
                },1000)
            } else {
                alert(data.message)
            }
        })
        .catch((err)=>{
            alert(err.message)
        })
        setNewImage(null)
        setPageName("")
        setDescription("")
    }


    return (
        <div className="col-lg-6 col-md-12 page_creation" style={{marginTop:"90px"}}>
            <div className="create-page-form mt-5">
            <h2 className="create-page-title" style={{fontWeight:"600",marginBottom:"15px"}}>Create a Page</h2>
           
                <form onSubmit={PageSubmit}>
               <div className="d-flex justify-content-center align-items-center">
                <div className="info-image" style={{position:"relative",marginBottom:"15px"}}>
                                    {!newImage &&(
                                        <img src= "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1709533220~exp=1709533820~hmac=47c52625a3fedd075b20fafcd8ba89b1bd35b9c720edc01c79db142d416e1892" alt="image" style={{ width: "200px", height: "200px",borderRadius:"50%"}} />
                                    )
                                    }
                                    {newImage &&(
                                        <img src={URL.createObjectURL(newImage)} alt="image" style={{ width: "200px", height: "200px",borderRadius:"50%" }} />
                                    )
                                    }
                                    {/* {newImage &&
                                        <button type="button" className="btn btn-primary" style={{ color: "black" }} >Save</button>
                                    } */}
                                    <div style={{position:"absolute",left:"95px",bottom:"5px"}}>
                                        <input
                                            style={{ display: 'none' }}
                                            ref={inputRef}
                                            
                                            type="file"
                                            onChange={(e) => {
                                                
                                                if (e.target && e.target.files && e.target.files.length > 0) {
                                                    setNewImage(e?.target?.files[0]);
                                                } else {
                                                    setNewImage(null);
                                                }
                                            }}
                                            accept="image/png, image/jpeg"
                                        />
                                        {!newImage && <i className="flaticon-photo-camera" onClick={handleClick}></i>}
                                    </div>
                                </div>
                                </div>
                             <div className="page-name" style={{marginBottom:"15px"}}>
                                <input type="text" placeholder="page name" className="page" value={name} onChange={(e)=>setPageName(e.target.value)} required/>
                                </div>  
                                   
                                <div className="page-form-group" style={{marginBottom:"15px"}}>
                    <textarea name="message" className="page-form-control"
                        placeholder="Write bio here..." value={description} onChange={(e)=>setDescription(e.target.value)}></textarea>
                </div>
                <button type="submit" className="btn btn-primary" >Create page</button>
                </form>
            </div>
           
        
        </div>
    )
}

export default CreatePage