import { useEffect, useState } from "react";
import AdminHeader from "./AdminHeader";
import { API_URL } from "../App";
import { token } from "../component/home/Home";

const DeleteCmtlike = () => {
  const [image, setImage] = useState([]);
  const [video, setVideo] = useState([]);
  const [val, setVal] = useState("");

  //image data

  const GetImages = () => {
    fetch(`${API_URL}/get/all/image/post`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setImage(data.message);
        // console.log(data.message)
      });
  };

  useEffect(() => {
    GetImages();
    Getvideo();
  }, []);

  const DeleteLike = (postId) => {
    fetch(`${API_URL}/delete-like/imagePost/${postId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {});
  };

  const DeleteComment = (postId) => {
    fetch(`${API_URL}/delete-comment/imagePost/${postId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {});
  };

  //videos data

  const Getvideo = () => {
    fetch(`${API_URL}/get/video/upload`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setVideo(data.message);
      });
  };

  const DeleteVideoLike = (postId) => {
    fetch(`${API_URL}/delete-like/videoPost/${postId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {});
  };

  const DeleteVideoComment = (postId) => {
    fetch(`${API_URL}/delete-comment/videoPost/${postId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {});
  };

  return (
    <div>
      <AdminHeader />
      <div className="card mt-5 mb-5">
        {val === "" ? (
          <div>
            <div
              className="mt-3 d-flex justify-content-between align-items-center table-responsive"
              style={{ width: "95%", margin: "auto" }}
            >
              <h6 style={{ fontWeight: "600" }}>Image likes & comments</h6>
              <select
                className="form-select"
                aria-label="Default select example"
                style={{ width: "180px" }}
                value={val}
                onChange={(e) => setVal(e.target.value)}
              >
                <option value="">Images</option>
                <option value="Videos">Videos</option>
              </select>
            </div>
            <div className="container py-5 table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="text-left">Profile</th>
                    <th className="text-center">Name</th>
                    {/* <th className="text-center">Email</th> */}
                    <th className="text-center">Total image likes</th>
                    <th className="text-center">Remove image likes</th>
                    <th className="text-center">Total image comments</th>
                    <th className="text-center">Remove image comments</th>
                  </tr>
                </thead>
                <tbody>
                  {image &&
                    image.map((ele) => (
                      <tr key={ele?._id}>
                        <td className="text-left">
                          <div className="row align-items-start">
                            <div className="col-auto">
                              <img
                                src={
                                  ele?.user
                                    ? ele?.user?.profile?.profileImage?.url
                                    : "https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
                                }
                                alt="user"
                                className="img-fluid"
                                width={"80"}
                                style={{ borderRadius: "5px" }}
                              ></img>
                            </div>
                            <div className="col pl-0 pt-2">
                              {/* <h6 className="text-left">{ele.username}</h6> */}
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          {ele?.user && ele?.user?.name}
                        </td>
                        {/* <td className="text-center">{ele.email}</td> */}
                        <td className="text-center">{ele?.like?.length}</td>
                        <td className="text-center">
                          <button
                            type="button"
                            className="btn btn-warning"
                            style={{
                              backgroundColor: "#FFC107",
                              marginRight: "10px",
                            }}
                            onClick={() => DeleteLike(ele?._id)}
                          >
                            Delete
                          </button>
                        </td>
                        <td className="text-center">{ele?.comment?.length}</td>
                        <td className="text-center">
                          <button
                            type="button"
                            className="btn btn-warning"
                            style={{
                              backgroundColor: "#FFC107",
                              marginRight: "10px",
                            }}
                            onClick={() => DeleteComment(ele?._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : val === "Videos" ? (
          <div>
            <div
              className="mt-3 d-flex justify-content-between align-items-center table-responsive"
              style={{ width: "95%", margin: "auto" }}
            >
              <h6 style={{ fontWeight: "600" }}>Video likes & comments</h6>
              <select
                className="form-select"
                aria-label="Default select example"
                style={{ width: "180px" }}
                value={val}
                onChange={(e) => setVal(e.target.value)}
              >
                <option value="">Images</option>
                <option value="Videos">Videos</option>
              </select>
            </div>
            <div className="container py-5 table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="text-left">Profile</th>
                    <th className="text-center">Name</th>
                    {/* <th className="text-center">Email</th> */}
                    <th className="text-center">Total video likes</th>
                    <th className="text-center">Remove video likes</th>
                    <th className="text-center">Total video comments</th>
                    <th className="text-center">Remove video comments</th>
                  </tr>
                </thead>
                <tbody>
                  {video &&
                    video.map((ele) => (
                      <tr key={ele?._id}>
                        <td className="text-left">
                          <div className="row align-items-start">
                            <div className="col-auto">
                              <img
                                src={
                                  ele?.user
                                    ? ele?.user?.profile?.profileImage?.url
                                    : "https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
                                }
                                alt="user"
                                className="img-fluid"
                                width={"80"}
                                style={{ borderRadius: "5px" }}
                              ></img>
                            </div>
                            <div className="col pl-0 pt-2">
                              {/* <h6 className="text-left">{ele.username}</h6> */}
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          {ele?.user && ele?.user?.name}
                        </td>
                        {/* <td className="text-center">{ele.email}</td> */}
                        <td className="text-center">{ele?.like?.length}</td>
                        <td className="text-center">
                          <button
                            type="button"
                            className="btn btn-warning"
                            style={{
                              backgroundColor: "#FFC107",
                              marginRight: "10px",
                            }}
                            onClick={() => DeleteVideoLike(ele?._id)}
                          >
                            Delete
                          </button>
                        </td>
                        <td className="text-center">{ele?.comment?.length}</td>
                        <td className="text-center">
                          <button
                            type="button"
                            className="btn btn-warning"
                            style={{
                              backgroundColor: "#FFC107",
                              marginRight: "10px",
                            }}
                            onClick={() => DeleteVideoComment(ele?._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DeleteCmtlike;
