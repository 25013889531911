import { Data_Request, Get_Current, Get_Data_Success, Get_userData } from "../actionType"

const init = {
    isError: false,
    isLoading: false,
    data: [],
    current:{},
    userData:[]
}

export const reducer = (state = init, { type, payload }) => {
    switch (type) {

        case Data_Request: {
            return { ...state, isLoading: true }
        }

        case Get_Data_Success: {
            return { ...state, isLoading: false, data: payload }
        }

        case Get_Current:{
            return {...state,current:payload}
        }

        case Get_userData:{
            return {...state,userData:payload}
        }

        default: {
            return state
        }

    }


}