import { useEffect, useState } from "react";
import AdminHeader from "./AdminHeader";
import { API_URL } from "../App";
import { token } from "../component/home/Home";
import { Button } from "@mui/material";

const User = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);

  const GetallUser = async (page) => {
    try {
      let res = await fetch(`${API_URL}/users?pageNumber=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await res.json();

      setUsers(data.users);
      // console.log(data.users)
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    GetallUser(page);
  }, [page]);

  return (
    <div>
      <AdminHeader />
      <div className="card mt-5 mb-5">
        <div className="container py-5 table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="text-left">Username</th>
                <th className="text-left">Name</th>
                <th className="text-center">Email</th>
                <th className="text-center">Total Freinds</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((ele) => (
                  <tr key={ele?._id}>
                    <td className="text-left">
                      <div className="row align-items-start">
                        <div className="col-auto">
                          <img
                            src={
                              ele?.profile?.profileImage?.url
                                ? ele?.profile?.profileImage?.url
                                : "https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
                            }
                            alt="user"
                            className="img-fluid"
                            width={"80"}
                            style={{ borderRadius: "5px" }}
                          ></img>
                        </div>
                        <div className="col pl-0 pt-2">
                          <h6>{ele?.username}</h6>
                        </div>
                      </div>
                    </td>
                    <td className="text-left">{ele?.name}</td>
                    <td className="text-center">{ele?.email}</td>
                    <td className="text-center">
                      {
                        ele?.friends?.filter(
                          (friend) => friend?.status === "friend"
                        )?.length
                      }
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="mb-3 d-flex justify-content-center align-items-center">
          <Button
            style={{
              backgroundColor: "rgb(231, 229, 229)",
              color: "black",
              height: "7vh",
              marginRight: "5px",
            }}
            disabled={page <= 1}
            onClick={() => setPage(page - 1)}
          >
            <i className="fa-solid fa-angle-left"></i>
          </Button>
          <Button
            style={{
              backgroundColor: "blue",
              color: "white",
              height: "7vh",
              marginRight: "5px",
            }}
            disabled
          >
            {page}
          </Button>
          <Button
            style={{
              backgroundColor: "rgb(231, 229, 229)",
              color: "black",
              height: "7vh",
            }}
            onClick={() => setPage(page + 1)}
          >
            <i className="fa-solid fa-angle-right"></i>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default User;
