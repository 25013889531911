import { useEffect, useRef, useState } from "react";
import "./AdminHeader.css";
import Chart from "chart.js/auto";
import { API_URL } from "../App";
import { token } from "../component/home/Home";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../Page-redux/commonPageReducer/action";

const AdminHeader = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [activeValue, setActiveValue] = useState(0);

  let dispatch = useDispatch();

  let user = useSelector((item) => item.commonPageReducer.current);
  useEffect(() => {
    fetch(`${API_URL}/active`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setActiveValue(data.activeUsers.length);
      });
  }, []);

  useEffect(() => {
    if (activeValue === 0) return; // Return if activeValue is 0 to avoid division by zero

    const remainingValue = 100 - activeValue;
    const chartData = [activeValue, remainingValue];

    if (chartInstance.current) {
      chartInstance.current.data.datasets[0].data = chartData;
      chartInstance.current.update();
    } else {
      const myChartRef = chartRef.current.getContext("2d");
      chartInstance.current = new Chart(myChartRef, {
        type: "doughnut",
        data: {
          labels: ["active users"],
          datasets: [
            {
              data: chartData,
              backgroundColor: ["rgb(54, 162, 235)", "rgb(255, 99, 132)"],
            },
          ],
        },
        options: {
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = context.label || "";
                  if (label) {
                    label += ": ";
                  }
                  const value = context.parsed || 0;
                  label += value.toFixed(2) + "%";
                  return label;
                },
              },
            },
          },
        },
      });
    }
  }, [activeValue]);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  return (
    <div className="Header-intro">
      <div style={{ position: "relative", bottom: "72px" }}>
        <h2>Welcome! {user?.name}👋,</h2>
      </div>
      <div className="total_user">
        <div>
          <label className="d-flex justify-content-center align-items-center">
            Total Active Users
          </label>
          <canvas ref={chartRef} style={{ width: "150px", height: "150px" }} />
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
