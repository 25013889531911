import React from 'react'
import Upload from './center/Upload'

import AllPost from './center/AllPost'

export default function Main() {
    return (
        <>
            <div className="col-lg-6 col-md-12">
                <div className="news-feed-area">
                    <Upload />
                    <AllPost />
                </div>
            </div>
        </>
    )
}
