import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL } from "../../App";
import { useState } from "react";

export const ForgotPassword = () => {
    const localEmail = localStorage.getItem("email");
    
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const toastFunction = (message) => {
        return toast(message, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeButton: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
       
        if (password === "") {
            toastFunction("Enter Password");
            return;
        }

        fetch(`${API_URL}/reset-password`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: JSON.stringify({ password: password.toString(), email: localEmail })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === true) {
                    toastFunction(data.message);
                    setTimeout(() => {
                        navigate("/");
                    }, 1000);
                } else {
                    // Handle error or other conditions
                }
            })
            .catch(error => {
                console.error("Error resetting password:", error);
                // Handle error appropriately (e.g., show error toast)
            });
    };

    return (
        <section className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="max-w-md w-full bg-white shadow-md rounded-lg p-6">
                <div className="mb-4">
                    <h2 className="text-xl font-semibold text-gray-800">Reset Your Password</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="password" className="text-sm text-gray-600">Password</label>
                        <input
                            type="password"
                            id="password"
                            className="w-full px-4 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-gray-100"
                            placeholder="Enter Password"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-lg shadow-md transition duration-300 ease-in-out"
                    >
                        Reset Password
                    </button>
                </form>
            </div>
        </section>
    );
};

