import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom"
import { API_URL } from "../../App";
import Footer from "../common/Footer";
import Friend from "../friend/Friend";
import { UserAbout } from "./UserAbout";
export const UserProfile = () => {
    // ............................

    const [showTab, setShowTab] = useState("about");
    const [userImage, setUserImage] = useState([])
    const [allPhoto, setAllPhoto] = useState(true)
    const [user, setUser] = useState({})

    const { id } = useParams();
    let token = localStorage.getItem("token")
    const getProfile = () => {
        fetch(`${API_URL}/api/users/friends/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => res.json())
            .then(data => {
                setUser(data)
                setUserImage(data?.profile?.profileImage?.url)
                
            })
            .catch((err)=>{
                console.error(err)
            })
    }
    useEffect(() => {
        getProfile();
    }, []);

    return (
        <>
            <div className="main-content-wrapper d-flex flex-column">
                <div className="content-page-box-area">
                    <div className="my-profile-inner-box">
                        <div className="profile-cover-image">
                            <a href="#">
                                <img src="/assets/images/my-profile-bg.jpg" alt="image" />
                            </a>
                        </div>
                        <div className="profile-info-box">
                            <div className="inner-info-box d-flex justify-content-between align-items-center">
                                <div className="info-image">
                                    {user && <a href="#">
                                        <img src={user?.profile?.profileImage?.url ? `${user.profile?.profileImage?.url}` : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1709533220~exp=1709533820~hmac=47c52625a3fedd075b20fafcd8ba89b1bd35b9c720edc01c79db142d416e1892"} alt="image" style={{ width: "200px", height: "200px" }} />
                                    </a>}

                                </div>
                                <div className="info-text ms-3">
                                    <h3><a href="#">{user?.name}</a></h3>
                                </div>
                                <ul className="statistics">
                                    <li>
                                        <span className="item-number">{user?.friends?.length}</span>
                                        <span className="item-text">Friends</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="profile-list-tabs">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className={`nav-link ${showTab == "about" ? "active" : ""}`} id="about-tab" data-bs-toggle="tab" role="tab" aria-controls="about"
                                            onClick={() => setShowTab("about")}
                                        >About</a>
                                    </li>

                                    <li className="nav-item">
                                        <a className={`nav-link ${showTab == "photos" ? "active" : ""}`} id="photos-tab" data-bs-toggle="tab" role="tab" aria-controls="photos"
                                            onClick={() => setShowTab("photos")}
                                        >Photos</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="tab-content" id="myTabContent">

                        <div className={`tab-pane fade ${showTab == "about" && "show active"} `} role="tabpanel"
                            onClick={() => setShowTab("about")}
                        >
                            <UserAbout profile={user} />
                        </div>

                        <div className={`tab-pane fade ${showTab == "friends" && "show active"} `} role="tabpanel"
                            onClick={() => setShowTab("friends")}
                        >
                            <Friend />
                        </div>

                        <div className={`tab-pane fade ${showTab == "photos" && "show active"} `} role="tabpanel"
                            onClick={() => setShowTab("photos")}
                        >
                            <div className="photos-inner-box-style d-flex justify-content-between align-items-center">
                                <div className="title">
                                    <h3>Photos</h3>
                                    <span>{userImage?.length}</span>
                                </div>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className={`nav-link ${allPhoto ? "active" : ""}`} id="all-photos-tab" data-bs-toggle="tab" href="#all-photos" role="tab" aria-controls="all-photos"
                                            onClick={() => setAllPhoto(true)}
                                        >All Photos</a>
                                    </li>

                                    <li className="nav-item">
                                        <a className={`nav-link ${allPhoto ? "" : "active"}`} id="photos-albums-tab" data-bs-toggle="tab" href="#photos-albums" role="tab" aria-controls="photos-albums"
                                            onClick={() => setAllPhoto(false)}
                                        > Videos</a>
                                    </li>
                                </ul>

                                <div className="photos-search-box">
                                    <form>
                                        <input type="text" className="input-search" placeholder="Search photos..." />
                                        <button type="submit"><i className="ri-search-line"></i></button>
                                    </form>
                                </div>
                            </div>

                            <div className="tab-content" id="myTabContent">
                                <div className={`tab-pane fade ${allPhoto ? "show active" : ""}`} id="all-photos" role="tabpanel">
                                    <div className="row justify-content-center">
                                        {/* .....................display profile image at the top.................... */}

                                        <div className="col-lg-3 col-sm-6">
                                            <div className="single-photos-card">
                                                <a href="#"><img src={`${userImage}`} alt="image" /></a>

                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${allPhoto ? "" : "show active"}`} id="photos-albums" role="tabpanel">
                                    <div className="row justify-content-center">

                                        {/* {videoList && videoList.map((item) => ( */}
                                        <>
                                            <div className="col-lg-3 col-sm-6">
                                                <div className="single-photos-card">
                                                    <video width="640" height="360" controls>
                                                        {/* <source src={item.videoFile.url} type="video/mp4" style={{ height: "400px", width: "400px" }} /> */}
                                                        Your browser does not support the video tag.
                                                    </video>
                                                    <div className="icon">
                                                        <a href="#"><i className="flaticon-remove-user"></i></a>
                                                        <a href="#"><i className="flaticon-download"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        {/* ))} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
