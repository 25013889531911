import { ExitIcon } from "@100mslive/react-icons";
import {
  selectIsConnectedToRoom,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import React from "react";
import { useNavigate } from "react-router-dom";

function Header() {
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const hmsActions = useHMSActions();
  const navigate=useNavigate()

  const LeaveRoom=()=>{
    hmsActions.leave();
    navigate("/message")
  }

  return (
    <header>
     
      {isConnected && (
        <button
          id="leave-btn"
          className="btn btn-danger"
          onClick={() => LeaveRoom()}
        >
          <ExitIcon style={{ marginLeft: "0.25rem" }} />End Call
        </button>
      )}
    </header>
  );
}

export default Header;
