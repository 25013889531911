import { API_URL } from "../App";

export const CreateRoom = async ( roomName ) => {
  try {
      const response = await fetch(`${API_URL}/create-room`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({
              "name": roomName,
              "description": "Sample description",
              "template_id": "6645e9e05afd7e4281e3170e"
          })
      })
      if (!response.ok) {
          // Handle non-200 status codes appropriately
          throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data

  } catch (error) {
      console.error("Error fetching auth token:", error);
  }

}




export const AuthToken = async (room_Id) => {
  try {
      const response = await fetch(`${API_URL}/auth-token`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({
              room_id: room_Id,
              role: "host",
              user_id: "test_user",
          }),
      });

      if (!response.ok) {
          // Handle non-200 status codes appropriately
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
  } catch (error) {
      console.error("Error fetching auth token:", error);

  }
};
