import React from 'react'

export default function LeftSide() {

    return (
        <>
            <div className="col-lg-3 col-md-12">
                <aside className="widget-area">
                    <div className="widget widget-explore-events">
                        <h3 className="widget-title">Explore Events</h3>
                        <article className="item">
                            <a href="#"><img src="assets/images/explore-events/explore-1.jpg" alt="event" /></a>
                        </article>
                        <article className="item">
                            <a href="#"><img src="assets/images/explore-events/explore-2.jpg" alt="event" /></a>
                        </article>
                    </div>
                </aside>
            </div>
        </>
    )
}
