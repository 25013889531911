import "./PageLeft.css"

const PageLeft=()=>{


    return (
        <div className="col-lg-3 col-md-12">
            <aside className="widget-area-page">
            <div className="widget widget-explore-events-page">
                        <p className="widget-title-page" >Explore Events</p>
                        <article className="item">
                            <a href="#"><img src="../assets/images/explore-events/explore-1.jpg" alt="image" /></a>
                        </article>
                        <article className="item">
                            <a href="#"><img src="../assets/images/explore-events/explore-2.jpg" alt="image" /></a>
                        </article>
                    </div>
            </aside>
        </div>
    )
}

export default PageLeft