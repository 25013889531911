import React from "react";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux";
import { store } from "./store";
import "./assets/css/bootstrap.min.css"
import "./assets/css/animate.min.css"
import "./assets/css/remixicon.css"
import "./assets/css/flaticon.css"
import "./assets/css/jquery-ui.min.css"
import "./assets/css/magnific-popup.min.css"
import "./assets/css/simplebar.min.css"
import "./assets/css/metismenu.min.css"
import "./assets/css/owl.carousel.min.css"
import "./assets/css/owl.theme.default.min.css"
import "./assets/css/style.css"
import "./assets/css/dark.css"
import "./assets/css/responsive.css";
import "./index.css";




createRoot(document.getElementById('root')).render(

  <HMSRoomProvider>
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer
          toastClassName={() =>
            "relative flex py-4 px-3 rounded overflow-hidden cursor-pointer bg-white shadow-lg"
          }
          bodyClassName={() => "text-black text-base font-normal"}
          position="bottom-left"
          autoClose={4000}
          hideProgressBar={true}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <App />
      </BrowserRouter>
    </Provider>
  </HMSRoomProvider>

)
