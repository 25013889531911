import React, { useEffect, useState } from "react";
import { API_URL } from "../../App";
import { token } from "../home/Home";
import { Link } from "react-router-dom";
export default function Friend() {
  const [showRequest, setShowRequest] = useState("show active");
  const [showFriendList, setShowFriendLit] = useState("");
  const [showRequestactive, setShowRequestactive] = useState("active");
  const [showFriendListactive, setShowFriendLitactive] = useState("");
  const friendRequest = () => {
    setShowRequest("show active");
    setShowFriendLit("");
    setShowRequestactive("active");
    setShowFriendLitactive("");
  };

  const friends = () => {
    setShowRequest("");
    setShowFriendLit("show active");
    setShowRequestactive("");
    setShowFriendLitactive("active");
  };

  const [friendList, setFriendList] = useState([]);
  const [friendRequestList, setFriendRequestList] = useState([]);

  const getAllFriend = async () => {
    try {
      const response = await fetch(`${API_URL}/get/all/friend`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.ok}`);
      }
      const data = await response.json();
      setFriendList(data.message);
    } catch (error) {
      console.error('Error fetching friends:', error);
      // Handle the error in a more user-friendly way (e.g., display an error message)
    }
  };


  
  const getFriendRequest = () => {
    fetch(`${API_URL}/get/all/friend/request`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
       
        setFriendRequestList(data.message);
      });
  };

  useEffect(() => {
    getAllFriend();
    getFriendRequest();
  }, []);

  const Unfriend = (id) => {
    
    fetch(`${API_URL}/unfollow/friend/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data)=>{

      })
  };

  return (
    <>
      <div className="main-content-wrapper d-flex flex-column">
        {/* <Heading /> */}
        <div className="content-page-box-area">
          {/* <div className="page-banner-box bg-4">
         
          </div> */}
             <h3>Friends</h3>
          <div className="friends-inner-box-style d-flex justify-content-between align-items-center margin-top-25">
            <div className="friends-search-box">
              <form>
                <input
                  type="text"
                  className="input-search"
                  placeholder="Search friends..."
                />
                <button type="submit">
                  <i className="ri-search-line"></i>
                </button>
              </form>
            </div>
          </div>

          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${showRequest} `}
              id="friend-requests"
              role="tabpanel"
            >
              <div className="row justify-content-center">
                {friendList.length > 0 ? (
                  friendList.map((item) => (

                    <div className="col-lg-3 col-sm-6" key={item?._id}>
                      <div className="single-friends-card">
                        <div className="friends-image">
                          <img
                            src="/assets/images/friends/friends-bg-1.jpg"
                            alt="image"
                          />
                          <Link to={`/user/profile/${item?._id}`}>
                            <div className="icon">
                              <a href="">
                                <i className="flaticon-user"></i>
                              </a>
                            </div>
                          </Link>
                        </div>
                        <div className="friends-content">
                          <div className="friends-info d-flex justify-content-between align-items-center">

                            <img
                              src={`${item?.profile?.profileImage?.url}`}
                              alt="image"
                              style={{ width: "100px", height: "100px" }}
                            />

                            <div className="text ms-3">
                              <h3>
                                <a href="#">{item?.name}</a>
                              </h3>
                              <span>{item?.friends?.length} Friends</span>
                            </div>
                          </div>
                          <div className="button-group d-flex justify-content-between align-items-center">
                            <div className="add-friend-btn">
                              <button
                                type="submit"
                                onClick={() => {
                                  Unfriend(item._id);
                                }}
                              >
                                Unfriend
                              </button>
                            </div>
                            <div className="send-message-btn">
                              <button type="submit">Send Message</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    // </>
                  ))
                ) : (
                  <>
                    <div>NO Data Found</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
