import { format } from "timeago.js";
import Dropdown from 'react-bootstrap/Dropdown';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useState } from "react";
import PagePostLike from "./PagePostLike";
import PagePostComment from "./PagePostComment";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const VideoCard = ({ postItem }) => {
    // const [data, setData] = useState([])
    const [open, setOpen] = useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [video, setVideo] = useState("")
    const [description, setDescription] = useState("")
    const [postId, setPostId] = useState("")
    const [newVideo, setNewVideo] = useState()
    // const [message, setMessage] = useState("")
    let route = window.location.href.split('/')[3]


    const editPost = (id, video, description) => {
        setPostId(id)
        setOpen(true)
        setVideo(video)
        setDescription(description)
    }

    const postDelete = (id) => {


    }

    const savePost = () => {




    }



    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        {!newVideo && <video width="100%" height="100%" controls autoPlay>
                            <source src={video} type="video/mp4" />
                        </video>}
                        {newVideo && (
                            <div className="shareImgContainer">
                                <video width="640" height="360" controls autoPlay>
                                    <source src={URL.createObjectURL(newVideo)} type="video/mp4" />
                                </video>
                                <br />
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-warning" style={{ margin: "10px" }} onClick={() => setNewVideo(null)}>cancel</button>

                                </div>

                            </div>

                        )}
                        <input type="file" style={{ margin: "auto" }} onChange={(e) => setNewVideo(e.target.files[0])} /></div>



                    <div>Description</div>
                    <input type="textarea" value={description} onChange={(e) => setDescription(e.target.value)} style={{ border: '1px solid black', borderRadius: "20px", padding: "10px", height: "10vh", width: "100%" }} />
                    <br />
                    <br />
                    <div>
                        <button onClick={() => savePost()} className="btn btn-primary" style={{ alignItems: "center" }}>Save</button>

                    </div>
                </Box>
            </Modal>
            <div className="news-feed news-feed-post">
                <div className="post-header d-flex justify-content-between align-items-center">
                    <div className="image">
                        <a href="my-profile.html"><img src={`${postItem.page_id.cover.url}`}
                            className="rounded-circle" alt="img" style={{ width: "50px" }} /></a>
                    </div>
                    <div className="info ms-3">
                        <span className="name">{postItem.page_id.name}</span>
                        <span className="small-text">{format(postItem.date)}</span>
                    </div>
                    {route === "" ? "" : <Dropdown style={{ border: "none" }}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ border: "none" }}>
                            <i className="fa-solid fa-light fa-caret-down" style={{ fontSize: "30px", border: 'none', padding: "5px" }}></i>

                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1" onClick={() => editPost(postItem._id, postItem.videoFile.url, postItem.description)}>Edit</Dropdown.Item>
                            <Dropdown.Item href="#/action-2" onClick={() => postDelete(postItem._id)}>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>}
                </div>
                <div className="post-body">
                    <p>{postItem.description}</p>
                    <div className="post-image">
                        <video width="100%" height="100%" controls autoPlay>
                            <source src={postItem.video.url} type="video/mp4" />
                        </video>
                    </div>
                    <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                        <li className="post-react">
                            <PagePostLike like={postItem.likes} postId={postItem._id} />
                        </li>
                        <li className="post-comment">
                            <PagePostComment comment={postItem.comment} postId={postItem._id} />
                        </li>
                        <li className="post-share">
                            <i className="flaticon-share"></i><span>Share</span> <span
                                className="number">0 </span>
                        </li>
                    </ul>

                    {/* here */}
                </div>
            </div>
        </>
    )
}
export default VideoCard