import { useEffect, useState } from "react";
import { API_URL } from "../App";
import { token } from "../component/home/Home";
import { Button } from "@mui/material";
import AdminHeader from "./AdminHeader";
import { toast } from "react-toastify";

const ChangeRole = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [roleSelections, setRoleSelections] = useState({});

  const toastFunction = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: true,
      closeButton: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const handleRoleChange = (userId, role) => {
    setRoleSelections((prevState) => ({
      ...prevState,
      [userId]: role,
    }));
  };

  // console.log(roleSelections)

  const GetallUser = async (page) => {
    try {
      let res = await fetch(`${API_URL}/users?pageNumber=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await res.json();

      setUsers(data.users);
    } catch (error) {
      console.error(error.message);
    }
  };

  const PostRole = (userId) => {
    const selectedRole = roleSelections[userId];

    if (!selectedRole) {
      return alert("Please select the role");
    }
    fetch(`${API_URL}/change-role/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ role: selectedRole }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        toastFunction(data.message);
        // console.log(data);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  useEffect(() => {
    GetallUser(page);
  }, [page]);

  return (
    <div>
      <AdminHeader />
      <div className="card mt-5 mb-5">
        <div className="container py-5 table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="text-left">Profile</th>
                <th className="text-left">Name</th>
                <th className="text-center">Email</th>
                <th className="text-center">Change Role</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((ele) => (
                  <tr key={ele._id}>
                    <td className="text-left">
                      <div className="row align-items-start">
                        <div className="col-auto">
                          <img
                            src={
                              ele?.profile?.profileImage?.url
                                ? ele?.profile?.profileImage?.url
                                : "https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
                            }
                            alt="user"
                            className="img-fluid"
                            width={"80"}
                            style={{ borderRadius: "5px" }}
                          ></img>
                        </div>
                      </div>
                    </td>
                    <td className="text-left">{ele?.name}</td>
                    <td className="text-center">{ele?.email}</td>
                    <td className="text-center">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        style={{ width: "140px" }}
                        value={roleSelections[ele._id] || ""}
                        onChange={(e) =>
                          handleRoleChange(ele?._id, e.target.value)
                        }
                      >
                        <option value="">Select role</option>
                        <option value="admin">Admin</option>
                        <option value="user">User</option>
                      </select>
                    </td>
                    <td className="text-center">
                      <button
                        type="button"
                        className="btn btn-success"
                        style={{ backgroundColor: "#198754" }}
                        onClick={() => PostRole(ele?._id)}
                      >
                        Submit
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="mb-3 d-flex justify-content-center align-items-center">
          <Button
            style={{
              backgroundColor: "rgb(231, 229, 229)",
              color: "black",
              height: "7vh",
              marginRight: "5px",
            }}
            disabled={page <= 1}
            onClick={() => setPage(page - 1)}
          >
            <i className="fa-solid fa-angle-left"></i>
          </Button>
          <Button
            style={{
              backgroundColor: "blue",
              color: "white",
              height: "7vh",
              marginRight: "5px",
            }}
            disabled
          >
            {page}
          </Button>
          <Button
            style={{
              backgroundColor: "rgb(231, 229, 229)",
              color: "black",
              height: "7vh",
            }}
            onClick={() => setPage(page + 1)}
          >
            <i className="fa-solid fa-angle-right"></i>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChangeRole;
