import React, { useState } from 'react';
import './auth.css'; // You can import your CSS file here
import { useNavigate } from 'react-router';
import { API_URL } from '../../App';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
function Register() {
  const [isSignInVisible, setIsSignInVisible] = useState(false);
  const navigate = useNavigate();

  const toggleForm = (e) => {
    e.preventDefault()
    setIsSignInVisible(!isSignInVisible);
    setTimeout(() => {
      navigate("/");
    }, 1000);
    return false
  };
  const toastFunction = (messages
  ) => {
    return toast(messages, {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: true,
      closeButton: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const userData = {};
    formData.forEach((value, key) => {
      userData[key] = value;
    });
    if (userData.username == "" || userData.name == "" || userData.email == "" || userData.password == "" || userData.mobile == "") {
      toastFunction("Fill All Credentials")
    }
    localStorage.setItem("email", userData.email)
   
    try {
      const response = await fetch(`${API_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: "application/json"
        },
        body: JSON.stringify(userData),
      })
      if (!response.ok) {
        // console.log("zxcvbnm,.....................", response.status)
        if (response.status == 409) {
          toastFunction('User already registered');
        }
        // toast(responseData.message)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      // console.log("responseData........", responseData);
      // Log the response from your backend
      toastFunction(responseData.message);
      // sessionStorage.setItem('email', JSON.stringify(userData.email))
      // Handle the result as needed, for example, redirect to login page
      navigate("/user/email/verification");
    } catch (error) {
      // Handle errors
      alert( error);
    }
  };

  // ..........

  const [showPassword, setShowPassword] = useState(false);
  const [showConformPassword, setShowConformPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConformPasswordVisibility = () => {
    setShowConformPassword(!showConformPassword);
  };
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const toggleLoginPasswordVisibility = () => {
    setShowLoginPassword(!showLoginPassword);
  };

  // ..........

  return (
      <section>
        <div className={`container ${isSignInVisible ? "" : "active"}`}>
          <div className="user signinBx">
            <div className="imgBx"><img src="/assets/images/auth/login.jpg" alt="" /></div>
            <div className="formBx">
              <form onSubmit={(e) => e.preventDefault()}>
                <h2>Sign In</h2>
                <input type="text" name="" placeholder="Email Address" />
                {/* <input type="password" name="" placeholder="Password" /> */}
                <div className='eyeDiv'>
                  <input className='eyeInput'
                    type={showLoginPassword ? 'text' : 'password'}
                    name="" placeholder="Password"
                  />
                  <span className='eyeBtn ' style={{ marginTop: "-12px" }}
                    onClick={toggleLoginPasswordVisibility}>
                    {showLoginPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </span>
                  <Link style={{ fontSize: "10px", color: 'blue' }} to="/forgot/password">Forgot Password</Link>
                </div>
                <input type="submit" name="" value="Login" />
                <p className="signup">
                  Don't have an account ?
                  <a onClick={(e) => toggleForm(e)}>Sign Up.</a>
                </p>
              </form>
            </div>
          </div>
          <div className="user signupBx">
            <div className="formBx">
              <form onSubmit={handleSubmit}>
                <h2>Create an account</h2>
                <input type="text" name="username" placeholder="Username" />
                <input type="text" name="name" placeholder="Name" />
                <input type="number" name="mobile" placeholder="Phone Number" />
                <input type="email" name="email" placeholder="Email Address" />
                {/* <input type="password" name="password" placeholder="Create Password" /> */}
                {/* ....................... */}
                <div className='eyeDiv'>
                  <input className='eyeInput'
                    type={showPassword ? 'text' : 'password'}
                    name="password" placeholder="Create Password"
                  />
                  <span className='eyeBtn'
                    onClick={togglePasswordVisibility}>
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </span>
                </div>
                <input type="submit" name="" value="Sign Up" />
                <p className="signup">
                  Already have an account ?
                  <a onClick={(e) => toggleForm(e)}>Sign in.</a>
                </p>
              </form>
            </div>
            <div className="imgBx"><img src="/assets/images/auth/login.jpg" alt="" /></div>
          </div>
        </div>
      </section>
  );
}

export default Register;
