import React, { useEffect } from 'react'
import Main from './Main'
import RightSide from './RightSide'
import LeftSide from './LeftSide'
import Heading from '../heading/heading'
import Footer from '../common/Footer'
export const token = localStorage.getItem("token")
export default function Home() {
    return (
        <>
            <div className="main-content-wrapper d-flex flex-column">
                {/* <Heading /> */}
                <div className="content-page-box-area">
                    <div className="row">
                        <RightSide />
                        <Main />
                        {/* <LeftSide /> */}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
