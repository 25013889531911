import axios from "axios"
import { API_URL } from "../../App"
import { token } from "../../component/home/Home"
import { Data_Request, Get_Current, Get_Data_Success, Get_userData } from "../actionType"



export const GetCommonPage = (id) => (dispatch) => {
    dispatch(DataRequest())
    fetch(`${API_URL}/page/get?type=all&id=${id}`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    .then((res)=>{
        return res.json()
    })
    .then((data)=>{
        dispatch(DataSuccess(data.pages))
    })
}


export const getCurrentUser=()=>(dispatch)=>{
    axios.get(`${API_URL}/current`,{
        headers:{
            "Content-Type":"application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    .then((res)=>{
        dispatch(GetCurrent(res.data.user))
    })
    .catch((err)=>{
        console.log(err)
    })
}




export let DataRequest=()=>{
return {type:Data_Request}
}


export let DataSuccess=(payload)=>{
return {type:Get_Data_Success,payload}
}


export let GetCurrent=(payload)=>{
return {type:Get_Current,payload}
}

export let GetUserData=(payload)=>{
    return {type:Get_userData,payload}
}