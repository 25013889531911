import React, { useEffect, useState } from 'react'
import { API_URL } from '../../../App';
import { Link } from 'react-router-dom';
// import { getProfile } from '../../../apiCall/GetAllFrindsList';
export default function Profile() {
    const [profile, setProfile] = useState({});
    const [friendProfile, setFriendProfile] = useState({});

    const [count, setCount] = useState()
    let token = localStorage.getItem("token")
    // console.log("getProfile............", getProfile());

    const getProfile = () => {
        fetch(`${API_URL}/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => res.json())
            .then(data => {
                if (data.status === true) {
                    // console.log("data...", data.message.friends
                    // );
                    setProfile(data.message)
                    setCount(data.count)
                } else {
                   
                }
            })
            .catch((err)=>{
                console.error(err)
            })
    }



    const getFriendProfile = () => {
        fetch(`${API_URL}/get/all/friend`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => res.json())
            .then(data => {
                // console.log('data, from friend side....', data)
                if (data.status === true) {
                    setFriendProfile(data.message)
                    
                } else {
                    return []
                }
            })
            .catch((err) => {
                return err
            })
    }

    useEffect(() => {
        getProfile();
        getFriendProfile();
    }, []);

    // console.log('friendProfile...............', friendProfile);

    return (
        <div className="widget widget-view-profile">
            <div className="profile-box d-flex justify-content-between align-items-center">
                <a href="my-profile.html"><img src={`${profile.profile ? profile?.profile?.profileImage?.url : ""}`} alt="profile" style={{ width: "100px", height: "60px" }} /></a>
                <div className="text ms-2">
                    <h3><a style={{ fontWeight: "bold", color: "black", textTransform: "capitalize" }} >{profile.name}</a></h3>
                    <span></span>
                </div>
            </div>
            <ul className="profile-statistics">
                <li>
                    <a href="#">
                        <span className="item-number">{count?.friends}</span>
                        <span className="item-text">Friends</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <span className="item-number">{count?.image}</span>
                        <span className="item-text">Images</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <span className="item-number">{count?.video}</span>
                        <span className="item-text">Videos</span>
                    </a>
                </li>
            </ul>
            <div className="profile-likes">
                <span><i className="flaticon-heart-shape-outline"></i>Friends</span>

                {/* ................... add image in map function ........... */}
                {friendProfile.length > 0 && friendProfile.map((e, index) => (
                    <ul key={index}>
                        <li >
                            <Link to={`/user/profile/${e?._id}`}>
                                {/* <img src="assets/images/user/user-22.jpg" alt="image" /> */}
                                <img src={e?.profile?.profileImage?.url ? e?.profile?.profileImage?.url : 'https://cdn.iconscout.com/icon/free/png-256/free-user-1648810-1401302.png'} alt='profile'/>
                            </Link>
                        </li>
                    </ul>
                ))}

            </div>
            <div className="profile-btn">
                <Link to="/friend" className="default-btn">View Friends</Link>
            </div>
        </div>
    )
}
