import { format } from "timeago.js";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { useEffect, useState } from "react";
import { API_URL } from "../../../App";
import PagePostLike from "./PagePostLike";
import PagePostComment from "./PagePostComment";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../../Page-redux/commonPageReducer/action";
import "./imageCard.css"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const ImageCard = ({ postItem }) => {

    // const [data, setData] = useState([])
    const [open, setOpen] = useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // const [title, setTitle] = useState("")
    const [image, setImage] = useState("")
    const [description, setDescription] = useState("")
    const [postId, setPostId] = useState("")
    const [newImage, setNewImage] = useState()

    let dispatch=useDispatch()
   
    let user=useSelector((item)=>item.commonPageReducer.current)
   
    const editPost = (id, image, description) => {
        setPostId(id)

        setOpen(true)
        setImage(image)

        setDescription(description)
    }

    const postDelete = () => {



    }

    const savePost = () => {



    }

    useEffect(()=>{
        dispatch(getCurrentUser())
    },[])

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        {!newImage && <img src={`${API_URL}/api/v1/blog/${image}`} style={{ height: "40vh", margin: "auto" }} alt="" />}
                        {newImage && (
                            <div className="shareImgContainer">
                                <img className="shareImg" src={URL.createObjectURL(newImage)} alt="post-img" style={{ height: "40vh" }} />
                                <br />
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-warning" style={{ margin: "auto" }} onClick={() => setNewImage(null)}>CANCEL</button>
                                </div>

                            </div>
                        )}
                        <input type="file" style={{ margin: "auto" }} onChange={(e) => setNewImage(e.target.files[0])} /></div>
                    <div>Description</div>
                    <input type="textarea" value={description} onChange={(e) => setDescription(e.target.value)} style={{ border: '1px solid black', borderRadius: "20px", padding: "10px", height: "10vh", width: "100%" }} />
                    <br />
                    <br />
                    <div>
                        <button onClick={() => savePost()} className="btn btn-primary" style={{ alignItems: "center" }}>Save</button>
                    </div>
                </Box>
            </Modal>
            <>
                <div className="news-feed news-feed-post mb-3">
                    <div className="post-header d-flex justify-content-between align-items-center">
                        <div className="image">
                            <img src={`${postItem.page_id?.cover ? postItem?.page_id?.cover?.url:"https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1709533220~exp=1709533820~hmac=47c52625a3fedd075b20fafcd8ba89b1bd35b9c720edc01c79db142d416e1892"}`}
                                className="rounded-circle" style={{ width: "50px" }} alt="img" />
                        </div>
                        <div className="info ms-3">
                            <span className="name">{postItem.page_id && postItem.page_id.name}</span>
                            <span className="small-text">{format(postItem.createdAt || postItem.date)}</span>
                        </div>
                        {user?._id === postItem?.page_id?.page_admin_id ? <Dropdown style={{ border: "none" }}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ border: "none"}}>
                                <i className="fa-solid fa-light fa-caret-down" style={{ fontSize: "30px", border: 'none', padding: "5px" }}></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1" onClick={() => editPost(postItem._id, postItem?.image?.url, postItem.description)}>Edit</Dropdown.Item>
                                <Dropdown.Item href="#/action-2" onClick={() => postDelete(postItem._id)}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> : ""}
                    </div>
                    <div className="post-body ">
                        <p>{postItem.description}</p>
                        <div className="post-image">
                            <img src={`${postItem.image.url}`} alt="img" />
                        </div>
                        <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                            <li className="post-react">
                                <PagePostLike like={postItem.likes} postId={postItem._id} />
                            </li>
                            <li className="post-comment" >
                                <PagePostComment comment={postItem.comments} postId={postItem._id} />
                            </li>
                            <li className="post-share">
                                <i className="flaticon-share"></i><span>Share</span> <span
                                    className="number">0</span>
                            </li>
                        </ul>

                    </div>
                </div>
            </>

        </>

    )
}

export default ImageCard