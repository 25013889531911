import { useEffect, useState } from "react";
import AdminHeader from "./AdminHeader";
import { token } from "../component/home/Home";
import { API_URL } from "../App";

const SpamReport = () => {
  const [data, setData] = useState([]);
  const [video, setVideo] = useState([]);
  const [image, setImage] = useState([]);
  const [val, setVal] = useState("");

  const GetSapm = () => {
    fetch(`${API_URL}/spam/report`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setData(data?.userSpam);
        setVideo(data?.videoSpam);
        setImage(data?.imageSpam);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  useEffect(() => {
    GetSapm();
  }, []);

  const BanUser = (userId) => {
    fetch(`${API_URL}/spam/user-ban/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {})
      .catch((err) => {
        console.error(err.message);
      });
  };

  const SuspendUser = (userId) => {
    fetch(`${API_URL}/spam/user-suspend/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {})
      .catch((err) => {
        console.error(err.message);
      });
  };

  return (
    <div>
      <AdminHeader />

      <div className="card mt-5 mb-5">
        {val === "" ? (
          <div>
            <div
              className="mt-3 d-flex justify-content-between align-items-center table-responsive"
              style={{ width: "95%", margin: "auto" }}
            >
              <h6 style={{ fontWeight: "600" }}>User Spam Report</h6>
              <select
                className="form-select"
                aria-label="Default select example"
                style={{ width: "250px" }}
                value={val}
                onChange={(e) => setVal(e.target.value)}
              >
                <option value="">Select the spam type</option>
                <option value="Image post spams">Image post spams</option>
                <option value="Video post spams">Video post spams</option>
              </select>
            </div>

            <div className="container py-5 table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="text-left">Username</th>
                    <th className="text-center">Email</th>
                    <th className="text-center">Spam type</th>
                    <th className="text-center">Description</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((ele) => (
                    <tr key={ele?._id}>
                      <td className="text-left">
                        <div className="row align-items-start">
                          <div className="col-auto">
                            <img
                              src={ele?.userId?.profile?.profileImage?.url}
                              alt="user"
                              className="img-fluid"
                              style={{
                                borderRadius: "5px",
                                width: "60px",
                                height: "60px",
                              }}
                            ></img>
                          </div>
                          <div className="col pl-0 pt-2">
                            <h6 className="text-center">
                              {ele?.userId?.username}
                            </h6>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">{ele?.userId?.email}</td>
                      <td className="text-center">{ele?.spamType}</td>
                      <td className="text-center">
                        {ele?.description ? ele?.description : "No description"}
                      </td>
                      <td className="text-right">
                        <button
                          type="button"
                          className="btn btn-warning"
                          style={{
                            backgroundColor: "#FFC107",
                            marginRight: "10px",
                          }}
                          onClick={() => BanUser(ele?.userId?._id)}
                        >
                          Ban
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          style={{ backgroundColor: "#FFC107" }}
                          onClick={() => SuspendUser(ele?.userId?._id)}
                        >
                          Suspend
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : val === "Image post spams" ? (
          <div>
            <div
              className="mt-3 d-flex justify-content-between align-items-center table-responsive"
              style={{ width: "95%", margin: "auto" }}
            >
              <h6 style={{ fontWeight: "600" }}>Image Post Report</h6>
              <select
                className="form-select"
                aria-label="Default select example"
                style={{ width: "250px", cursor: "pointer" }}
                value={val}
                onChange={(e) => setVal(e.target.value)}
              >
                <option value="">Select the spam type</option>
                <option value="Image post spams">Image post spams</option>
                <option value="Video post spams">Video post spams</option>
              </select>
            </div>
            <div className="container py-5 table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="text-left">Username</th>
                    <th className="text-center">Spam type</th>
                    <th className="text-center">Description</th>
                    <th className="text-center">Spam Image</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {image &&
                    image.map((ele) => (
                      <tr key={ele?._id}>
                        <td className="text-left">
                          <div className="row align-items-start">
                            <div className="col-auto">
                              <img
                                src={ele?.userId?.profile?.profileImage?.url}
                                alt="user"
                                className="img-fluid"
                                style={{
                                  borderRadius: "5px",
                                  width: "60px",
                                  height: "60px",
                                }}
                              ></img>
                            </div>
                            <div className="col pl-0 pt-2">
                              <h6 className="text-center">
                                {ele?.userId?.username}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td className="text-center">{ele?.spamType}</td>
                        <td className="text-center">
                          {ele?.description ? ele?.description : "No description"}
                        </td>
                        <td className="text-center">
                          <div
                            className="col-auto text-end"
                            style={{
                              position: "relative",
                              width: "200px",
                              height: "100px",
                              backgroundColor: "#F4F7FC",
                              left: "25px",
                            }}
                          >
                            <img src={ele?.imgPostId?.imageUrl?.url} alt="post"/>
                          </div>
                        </td>
                        <td className="text-right">
                          <button
                            type="button"
                            className="btn btn-warning"
                            style={{
                              backgroundColor: "#FFC107",
                              marginRight: "10px",
                            }}
                            onClick={() => BanUser(ele?.userId?._id)}
                          >
                            Ban
                          </button>
                          <button
                            type="button"
                            className="btn btn-warning"
                            style={{ backgroundColor: "#FFC107" }}
                            onClick={() => SuspendUser(ele?.userId?._id)}
                          >
                            Suspend
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : val === "Video post spams" ? (
          <div>
            <div
              className="mt-3 d-flex justify-content-between align-items-center table-responsive"
              style={{ width: "95%", margin: "auto" }}
            >
              <h6 style={{ fontWeight: "600" }}>Video Post Report</h6>
              <select
                className="form-select"
                aria-label="Default select example"
                style={{ width: "250px" }}
                value={val}
                onChange={(e) => setVal(e.target.value)}
              >
                <option value="">Select the spam type</option>
                <option value="Image post spams">Image post spams</option>
                <option value="Video post spams">Video post spams</option>
              </select>
            </div>
            <div className="container py-5 table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="text-left">Username</th>

                    <th className="text-center">Spam type</th>
                    <th className="text-center">Description</th>
                    <th className="text-center">Spam Video</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {video.map((ele) => (
                    <tr key={ele?._id}>
                      <td className="text-left">
                        <div className="row align-items-start">
                          <div className="col-auto">
                            <img
                              src={ele?.userId?.profile?.profileImage?.url}
                              alt="user"
                              className="img-fluid"
                              style={{
                                borderRadius: "5px",
                                width: "60px",
                                height: "60px",
                              }}
                            ></img>
                          </div>
                          <div className="col pl-0 pt-2">
                            <h6 className="text-center">
                              {ele?.userId?.username}
                            </h6>
                          </div>
                        </div>
                      </td>

                      <td className="text-center">{ele?.spamType}</td>
                      <td className="text-center">
                        {ele?.description ? ele?.description : "No description"}
                      </td>
                      <td className="text-center">
                        <div
                          className="col-auto text-end"
                          style={{
                            position: "relative",
                            width: "200px",
                            height: "100px",
                            backgroundColor: "#F4F7FC",
                            left: "25px",
                          }}
                        >
                          <video
                            width="100%"
                            height="100%"
                            controls
                            autoPlay
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          >
                            <source
                              src={
                                ele?.videoPostId && ele?.videoPostId?.videoFile?.url
                              }
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      </td>
                      <td className="text-right">
                        <button
                          type="button"
                          className="btn btn-warning"
                          style={{
                            backgroundColor: "#FFC107",
                            marginRight: "10px",
                          }}
                          onClick={() => BanUser(ele?.userId?._id)}
                        >
                          Ban
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          style={{ backgroundColor: "#FFC107" }}
                          onClick={() => SuspendUser(ele?.userId?._id)}
                        >
                          Suspend
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SpamReport;
