import React, { useState, useEffect } from "react";
import "./heading.css";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { API_URL } from "../../App";
import { token } from "../home/Home";
import { Link } from "react-router-dom";
import { addFriend } from "../../apiCall/GetAllFrindsList";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "90%",
  bgcolor: 'background.paper',
  p: 4,
};
const Header = () => {
  const [isMenuActive, setMenuActive] = useState(false);
  // const [isSearchActive, setSearchActive] = useState(false);
  const [friendList, setFriendList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [profile, setProfile] = useState();
  const [profilePic, setProfilePic] = useState("")
  const [request,SetRequest]=useState(true)
  const [friend,setFriend]=useState(null)

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };
  
  const [notifCount, setNotifCount] = useState(null)
  const getNotification = () => {
      fetch(`${API_URL}/notification`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setNotifCount(data?.total)
          
        });
    };




    const getProfile = async () => {
      try {
        const response = await fetch(`${API_URL}/profile`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          }
        });
        
        const data = await response.json();
    
        if (data.status === true) {
          setProfilePic(data?.message?.profile?.profileImage?.url);
          setProfile(data?.message);
        } 
      } catch (error) {
        console.error(error);
      }
    }
    

  useEffect(()=>{
    getProfile();
    getNotification()
  },[])

  const closeMenu = () => {
    setMenuActive(false);
  };

  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const getFriend = () => {

    if (searchInput === "") {
      return;
    }
    fetch(`${API_URL}/search/friend/${searchInput}`, {
      method: "GET",

    }).then(res => res.json())
      .then(data => {
       
        setFriendList(data.message)
        setOpen(true)
      })
  }


const addFriendinSearch = (id)=>{
  addFriend(id)
  .then((data)=>{
    if(data && data.status===true){
      alert("add friend successfully")
    }
    else{
      SetRequest(data.status)
      
    }
  })
  .catch((error)=>{
    console.error(error)
  })
}


// friend requests 
const getFriendRequest = () => {
  fetch(`${API_URL}/get/all/friend/request`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      setFriend(data?.message?.length);
      
    });
};

useEffect(()=>{
  getFriendRequest()
  getFriend()
},[])



  //seen notification

  const SeenNotification=(id)=>{
    fetch(`${API_URL}/notification/seen?_id=${id}`,{
      method:"PUT",
      headers:{
        "Content-Type":"application/json",
        "Authorization":`Bearer ${token}`
      }
    })
    .then((res)=>{
      return res.json()
    })
    .then((data)=>{
    //  console.log(data)
    })
    .catch((err)=>{
      console.error(err.message)
    })

  }


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ width: "100%", height: "80vh", overflow: "hidden", overflowY: "scroll" }} className="scrollb">

            <h4 style={{ margin: "auto", textAlign: "center" }}>Friend List</h4>
            {friendList.length>0 ? friendList.map((item) => (
              <>
                <div style={{ display: "flex", justifyContent: "space-around", marginTop: "10px", alignItems: "center" }}>
                  <div style={{ padding: "10px" }}><img src={item.profile ? `${item?.profile?.profileImage?.url}` : "https://img.freepik.com/free-icon/user_318-792327.jpg"} style={{ width: "70px", height: "70px", borderRadius: "50%" }} alt="img"/></div>
                  <div style={{ padding: "10px" }}><div style={{ fontWeight: "bold" }}>
                    {item.name}
                  </div>
                    {/* <span>4 mutual friends</span> */}
                  </div>
                  <div style={{ padding: "10px" }}><button disabled={!request}  className="btn btn-primary" onClick={()=>{
                    addFriendinSearch(item?._id)
                  }}>{request?"request":"Already sent"}</button></div>
                </div>
                <hr style={{ width: "80%", margin: "auto" }} />
              </>
            )) : <div style={{position:"relative",top:"10%"}}>User Not Found</div>}
          </div>
        </Box>
      </Modal>
      <header className="header" id="header">
        <nav className="navbar container">
          <img
            style={{ borderRadius: "50%", backgroundColor: "white" }}
            src="/assets/images/logo.png"
            alt="img"
            height="40px"
            width="40px"
          />
          <div className="search-box m-auto d-flex justify-content-between search-form">
            <input
              type="text"
              className=" search-input"
              placeholder="Search..."
              style={{ color: "white" }}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              type="button"

              onClick={getFriend}
            >
              <i className="ri-search-line search-icon"></i>
            </button>
          </div>
          <div className="burger" id="burger" onClick={toggleMenu}>
            <span className="burger-line"></span>
            <span className="burger-line"></span>
            <span className="burger-line"></span>
          </div>
          <span className="overlay" onClick={closeMenu}></span>
          <div className={`menu ${isMenuActive ? "is-active" : ""}`}>
            <ul className="menu-inner mt-3">
              <li className="menu-item">
                <Link
                  className="menu-link d-flex home-btn"
                  to="/"
                  onClick={closeMenu}
                >
                  <i className="flaticon-home"></i>
                  <span className="nav-text"> Home</span>
                </Link>
              </li>
              <li className="menu-item">
                <Link
                  className=" menu-link d-flex"
                  to="/FriendRequest"
                  onClick={closeMenu}
                >
                  <div className="friend-requests-btn nav-btn">
                    <i className="flaticon-user"></i>
                    {friend ? <span className="span">{friend}</span> : null}
                  </div>
                  <span className="nav-text"> Friend</span>
                </Link>
              </li>
              <li className="menu-item">
                <Link className="menu-link d-flex" to="/message" onClick={closeMenu}>
                  <div className="messages-btn nav-btn">
                    <i className="flaticon-email"></i>
                    {/* <span className="span"></span> */}
                  </div>
                  <span className="nav-text">Messages</span>
                </Link>
              </li>
              <li className="menu-item ">
                <Link
                  className="menu-link d-flex "
                  to="/notification"
                  onClick={closeMenu}
                >
                  <div className=" notifications-btn nav-btn">
                    <i className="flaticon-bell" onClick={()=>SeenNotification()}></i>
                    {notifCount ? <span className="span">{notifCount}</span> : null}
                  </div>
                  <span className="nav-text">Notification</span>
                </Link>
              </li>
              <li className="menu-item profile-nav-item">
                <Link className="menu-link" to="/profile" onClick={closeMenu}>
                  <div className="menu-profile m-auto d-flex justify-content-between ">
                    <img
                      src={`${profilePic ? profilePic : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1709533220~exp=1709533820~hmac=47c52625a3fedd075b20fafcd8ba89b1bd35b9c720edc01c79db142d416e1892"}`}
                      className="rounded-circle "
                      alt="img"
                      width={"30px"}
                      height={"30px"}
                    />
                    <span className="name" style={{ color: "white" }}>
                      {profile ? profile?.name : ""}
                    </span>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
