import { Data_Request, Get_Data_Success } from "../actionType"

const init={
isLoading:false,
data:[],
}

export const reducer = (state=init,{ type, payload }) => {
    switch (type) {

        case Data_Request:{
            return {...state,isLoading:true}
        }

        case Get_Data_Success:{
            return {...state,isLoading:false,data:payload}
        }

        default: {
            return state
        }

    }


}