import React, { useEffect } from "react";
import { toast } from "react-toastify"
import { io } from "socket.io-client";
import {
  HMSRoomState,
  selectIsConnectedToRoom,
  selectRoomState,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Loader } from "../src/video_chat/Loader";
import AllRoute from "./utils/AllRoute";
export const API_URL ="https://myyearbook.in:8004";
const user = JSON.parse(localStorage.getItem("user"))


const toastFunction = (messages
) => {
  return toast(messages, {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeButton: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  })
}

const loadingStates = [HMSRoomState.Connecting, HMSRoomState.Disconnecting];

export default function App() {

  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const roomState = useHMSStore(selectRoomState);
  const hmsActions = useHMSActions();

  useEffect(() => {
    if (user) {
      const socket = io(`${API_URL}/notification`, {
        query: { id: user._id },
      });

      socket.on('connect', () => {
        // console.log('Connected to notification server');
        const userId = user?._id; // Replace 'userId' with the actual user ID
        socket.emit('connection', { id: userId });
      });

      socket.on('disconnect', () => {
        // console.log('Disconnected from notification server');
      });

      socket.on('notification', (data) => {
        toastFunction(`${data.senderName} ${data.message}`)
      });
    }
  }, []);


  useEffect(() => {
    window.onbeforeunload = () => {
      if (isConnected) {
        hmsActions.leave();
      }
    };
  }, [hmsActions, isConnected]);

  if (loadingStates.includes(roomState) || !roomState) {
    return <Loader />;
  }

  return (
    <>

      <AllRoute />

    </>
  );
}
