import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL } from "../../App";
import { useState } from "react";

export const Otp = () => {
    const localEmail = localStorage.getItem("email");
    
    const [otp, setOtp] = useState('');
    const [emailShow, setEmailShow] = useState(false);
    const navigate = useNavigate();

    const toastFunction = (message) => {
        return toast(message, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeButton: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        fetch(`${API_URL}/verify-OTP`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: JSON.stringify({ otp, email: localEmail })
        })
            .then((res) => res.json())
            .then((data) => {
                if (otp.length === 0) {
                    toastFunction('Enter OTP');
                } else if (data.status === false) {
                    toastFunction(data.message);
                    setEmailShow(!emailShow);
                } else {
                    toastFunction(data.message);
                    navigate("/forgot/Password");
                }
            });
    };

    return (
        <section className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="max-w-md w-full bg-white shadow-md rounded-lg p-6">
                <div className="mb-4">
                    <h2 className="text-xl font-semibold text-gray-800">Verify OTP</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <input
                            type="text"
                            id="otp"
                            className="w-full px-4 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-gray-100"
                            placeholder="Enter Your OTP"
                            onChange={(e) => setOtp(e.target.value)}
                            maxLength={6} // Limiting input to 6 characters
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-lg shadow-md transition duration-300 ease-in-out"
                    >
                        Enter OTP
                    </button>
                </form>
            </div>
        </section>
    );
};

