import React, { useEffect, useState } from 'react';
import { API_URL } from '../../../App';
import { token } from '../Home';
import PostCard1 from './PostCard1';
import VideoCard1 from './VideoCard1';
import Loading from '../../profile/Skeleton';
export default function AllPost() {
    const [list, setList] = useState([])
    const [loading,setLoading]=useState(false)

    const getAllPost = async () => {
        try {
          setLoading(true)
          let uri = "";
          if (window.location.href.split('/')[3] === "") {
            uri = "/home/post";
          } else {
            uri = "/all/image/video";
          }
      
          const response = await fetch(`${API_URL}${uri}`, {
            method: "GET",
            headers: {
              "Authorization": `Bearer ${token}`
            }
          });
          if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
          }
          const data = await response.json();
          setList(data.message);
          setLoading(false)
        } catch (error) {
          console.error('Error fetching posts:', error);
          // Handle the error in a more user-friendly way (e.g., display an error message)
        }
      };

    useEffect(() => {
        getAllPost()
    }, [])

    if(loading ){
      return <Loading/>
    }
  
    return (
        <>
            {list && list.map((item) => (
                <div key={item._id}>
                    {item.imageUrl ? <PostCard1 postItem={item} /> : <VideoCard1 postItem={item} />}
                </div>
            ))}
        </>
    )
}
