import {
  selectPeers,
  selectPeersScreenSharing,
  useHMSStore,
} from "@100mslive/react-sdk";
import Peer from "./Peer";
import { ScreenTile } from "./ScreenTile";
import { useEffect, useState } from "react";
import { useHMSActions } from "@100mslive/react-sdk";
import { useNavigate } from "react-router-dom";

function Conference() {
  const peers = useHMSStore(selectPeers);
  const presenters = useHMSStore(selectPeersScreenSharing);
  const hmsActions = useHMSActions();
  const [callWait, setCallWait] = useState(false);
  const navigate = useNavigate()

  const DeclineCall = () => {
    hmsActions.leave();
    navigate("/message")
  }

  useEffect(() => {
    if (peers.length === 1) {
    const timerId = setTimeout(() => {
        setCallWait(true)
    }, 30000);

    return () => {
      clearTimeout(timerId)
    }
  }
  }, [peers.length])

  return (
    <div className="conference-section">
      {
        callWait && (
          <div>
            <div className="fixed top-0 left-0 right-0 z-50 bg-gray-600 bg-opacity-50 overflow-y-auto h-screen flex justify-center items-center">
              <div className="bg-gray-800 rounded-lg p-4 shadow-md px-5 py-5" >
                <h3 className="text-lg font-medium mb-4 text-gray-300 text-center">Call Info</h3>
                <p className="text-gray-200 mb-6">
                  Didn't pick up the call
                </p>
                <div className="flex justify-center">

                  <button
                    className="text-white bg-red-600 hover:text-gray-200  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    onClick={DeclineCall}
                  >
                    End call
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }
      <div className="peers-container">
        {peers.map((peer) => (
          <Peer key={peer.id} peer={peer} />
        ))}
        {presenters.map((peer) => (
          <ScreenTile key={"screen" + peer.id} peer={peer} />
        ))}
      </div>
    </div>
  );
}

export default Conference;
