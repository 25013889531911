import { API_URL } from "../../App"
import { token } from "../../component/home/Home"
import { Data_Request, Get_Data_Success } from "../actionType"


export const GetUserPage = (id) => (dispatch) => {
    dispatch(DataRequest())
    fetch(`${API_URL}/page/get?type=managed&id=${id}`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    .then((res)=>{
        return res.json()
    })
    .then((data)=>{
        dispatch(DataSuccess(data.pages))
    })
}



export let DataRequest = () => {
    return { type: Data_Request }
}


export let DataSuccess = (payload) => {
    return { type: Get_Data_Success, payload }
}