import "./styles.css";
import Conference from "./Conference";
import { useEffect } from "react";
import {
  HMSRoomState,
  selectIsConnectedToRoom,
  selectRoomState,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import Footer from "./Footer";
import { Loader } from "./Loader";
import Header from "./Header";
import { AuthToken, CreateRoom } from "../utils/api";
import { useNavigate } from "react-router-dom";


const loadingStates = [HMSRoomState.Connecting, HMSRoomState.Disconnecting];

const Main = () => {

  console.log("function called")

  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const roomState = useHMSStore(selectRoomState);
  const hmsActions = useHMSActions();
  const navigate = useNavigate();

  useEffect(() => {
    window.onbeforeunload = () => {
      if (isConnected) {
        hmsActions.leave();
        navigate("/message")
      }
    };
    handleSubmit();
  }, [hmsActions, isConnected]);

  const handleSubmit = async () => {
    if(!window.location.search) return;
    const searchParams = new URLSearchParams(window.location.search);
    const roomName = searchParams.get('userName') || null
    if(!roomName) return;
    const roomVal= await CreateRoom(roomName)
    const token = await AuthToken(roomVal?.id);
    console.log(token,"token_value")
    hmsActions.join({ authToken: token.token });
  };

  if (loadingStates.includes(roomState) || !roomState) {
    return <Loader/>;
  }

  return (
    <>
      {isConnected && (
        <div className="bg-#010101 w-full h-full">
          <Header />
          <Conference />
          <Footer />
        </div>
      ) 
     
      }
    </>
  )
}

export default Main