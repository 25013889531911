import React, { useRef, useEffect } from "react";

const CameraCapture = ({ onPost }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = () => {
            videoRef.current.play();
            console.log("Video stream loaded and playing");
          };
        })
        .catch((err) => {
          console.error("Error accessing webcam: ", err);
        });
    }
  }, []);

  const takePhoto = () => {
    if (videoRef.current) {
      const width = 400;
      const height = 300;
      const video = videoRef.current;
      const canvas = document.createElement("canvas");

      canvas.width = width;
      canvas.height = height;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, width, height);
      canvas.toBlob((blob) => {
        onPost(blob); // Send the photo blob back to the Upload component
      }, "image/jpeg");
    } else {
      console.error("Video element is not available");
    }
  };

  return (
    <div className="camera">
      <div className="video-container">
        <video ref={videoRef}></video>
        <button className="take-photo-btn" onClick={takePhoto}>
          Take Photo<i className="flaticon-camera"></i>
        </button>
      </div>
    </div>
  );
};

export default CameraCapture;
