import {legacy_createStore,applyMiddleware, combineReducers} from "redux"
import {reducer as commonPageReducer} from "./Page-redux/commonPageReducer/reducer"
import {reducer as userPageReducer} from "./Page-redux/userPageReducer/reducer"
import {thunk} from "redux-thunk"

let rootReducer=combineReducers({
commonPageReducer,
userPageReducer
})

export const store=legacy_createStore(rootReducer,applyMiddleware(thunk))