import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL } from "../../App";
import { useState } from "react";

export const VerifyEmail = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const toastFunction = (message) => {
        return toast(message, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeButton: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };

    const VerifyEmailFun = (e) => {
        e.preventDefault();
        fetch(`${API_URL}/send-OTP`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({ email }),
        })
            .then((res) => res.json())
            .then((data) => {
                toastFunction('OTP Send On Email');
                localStorage.setItem('email', email);
                navigate('/verify-otp');
            });
    };

    return (
        <section className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="max-w-md w-full bg-white shadow-md rounded-lg p-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">Verify Your Email</h2>
                <p className="text-sm text-gray-600 mb-4">Enter your registered email ID</p>
                <form onSubmit={(e) => VerifyEmailFun(e)}>
                    <div className="mb-4">
                        <input
                            type="email"
                            id="email"
                            className="w-full px-4 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-gray-100"
                            placeholder="Enter Your Email"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-lg shadow-md transition duration-300 ease-in-out"
                    >
                        Send OTP
                    </button>
                </form>
            </div>
        </section>
    );
};

export default VerifyEmail;
