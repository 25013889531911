import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function Loading() {
    return (
        <div >

            {
                window.location.pathname == "/" ? (
                    <div style={{ marginTop: "5px" }}>
                        <Stack spacing={2}>
                            <Skeleton variant="rectangular" width="100%" height={230} />
                            <Skeleton variant="rounded" width="100%" height={230} />
                        </Stack>
                    </div>
                ) : (
                    <div style={{ position: "relative", bottom: "25px" }}>
                        <Stack spacing={2}>
                            <div style={{ width: "96%", margin: "auto",marginTop:"125px" }}>
                                <Stack spacing={2}>
                                    <Skeleton variant="rectangular" width="100%" height={230} />
                                    <Skeleton variant="rounded" width="100%" height={230} />
                                </Stack>
                            </div>
                        </Stack>
                    </div>

                )
            }
        </div>

    );
}
