export const UserAbout = ({ profile }) => {
   
    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-12">
                    <div className="about-personal-information">
                        <div className="about-header d-flex justify-content-between align-items-center">
                            <div className="title">Personal Information</div>
                        </div>

                        <ul className="information-list">  
                            <li>
                                <span>Gender:</span> {profile?.profile?.gender ? profile?.profile?.gender : "NA"}
                            </li>
                            <li>
                                <span>Relationship Status:</span>{" "}
                                {profile?.profile?.materialStatus ? profile?.profile?.materialStatus : " NA"}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-9 col-md-12">
                    <div className="about-details-information">
                        <div className="information-box-content">
                            <div className="information-header d-flex justify-content-between align-items-center">
                                <div className="title">About Me!</div>
                            </div>
                            <div className="content">
                                <p>{profile?.profile?.bio ? profile?.profile?.bio : "NA"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}